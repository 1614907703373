/* For use in src/lib/core/theming/_palette.scss */
$md-xpath-accent: (
  50: #efe8e4,
  100: #d8c6bc,
  200: #bea08f,
  300: #a37a62,
  400: #905e40,
  500: #7c411e,
  600: #743b1a,
  700: #693216,
  800: #5f2a12,
  900: #4c1c0a,
  A100: #ff9f82,
  A200: #ff774f,
  A400: #ff501c,
  A700: #ff3c03,
  A800: #708295,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
