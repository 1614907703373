/* For use in src/lib/core/theming/_palette.scss */
$md-xpath-accent: (
    50 : #efe8e4,
    100 : #d8c6bc,
    200 : #bea08f,
    300 : #a37a62,
    400 : #905e40,
    500 : #7c411e,
    600 : #743b1a,
    700 : #693216,
    800 : #5f2a12,
    900 : #4c1c0a,
    A100 : #ff9f82,
    A200 : #ff774f,
    A400 : #ff501c,
    A700 : #ff3c03,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);