.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__mixedmark {
  border-color: #fff !important;
}

.mat-mdc-checkbox {
  .mdc-checkbox {
    padding: 0 !important;
  }

  .mdc-checkbox__ripple {
    background-color: transparent !important;
  }

  .mdc-checkbox__native-control {
    width: 16px !important;
    height: 16px !important;
    top: 0 !important;
    right: 0 !important;
  }

  .mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
    @apply border border-gray #{!important};
  }
}
