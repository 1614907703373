:root {
  --color-primary: #f96332;
  --color-secondary: #708295;
  --color-danger: #f2545b;
  --color-warning: #ffb545;
  --color-success: #89b51a;
  --color-info: #00639f;
  --color-orange: #f96332;
  --color-yellow: #ffb545;
  --color-green: #89b51a;
  --color-blue: #00639f;
  --color-gray-dark: #708295;
  --color-gray-light: #d0dae4;
  --color-red: #f2545b;
  --color-white: #fff;
}
