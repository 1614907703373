@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* general */
html {
  min-height: 100%;
  margin: 0;
}
body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  /* background: linear-gradient(180deg, #fefefe 0%, #ebedf0 100%); */
  background: #f4f5f6;
  box-shadow: 0 2px 12px 0 rgba(255, 255, 255, 0.15);
  word-break: break-word;
}
* {
  font-family: "Montserrat", sans-serif;
}
.window-width {
  display: none;
}
*:focus {
  outline: 0 !important;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:hover {
  /* -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out; */
}
a:focus {
  outline: 0;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.clear {
  clear: both;
}
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
table a {
  color: #333;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  margin: 0 !important;
}
.lock-scroll {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.overflow-v {
  overflow: visible !important;
}
.fl-in {
  float: initial !important;
}
.fl-r {
  float: right;
}
.fl-l {
  float: left;
}
.full-width {
  width: 100% !important;
}
.ta-r {
  text-align: right !important;
}
.ta-l {
  text-align: left !important;
}

.m-10-0 {
  margin: 10px 0px;
}
.outside-dropdown {
  width: 220px;
  display: inline-block;
}
.m-l-r-15 {
  margin: 0 15px;
}
.fsz-15 {
  font-size: 15px !important;
}
.fs-13 {
  font-size: 13px !important;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-30 {
  font-size: 30px !important;
}

.fs-12 {
  font-size: 12px;
}
.l-h-16 {
  line-height: 16px;
}
.l-h-18 {
  line-height: 18px;
}
.l-h-20 {
  line-height: 20px;
}
.l-h-22 {
  line-height: 22px;
}
.l-h-no {
  line-height: unset !important;
}
.m-0 {
  margin: 0;
}

.m-b-25 {
  margin-bottom: 25px;
}
.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-t-30 {
  margin-top: 30px;
}
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-5 {
  margin-top: 5px;
}
.font-12 {
  font-size: 12px;
}
.no-bord {
  border: none !important;
}
.clear {
  clear: both;
}
.cursor-zoom {
  cursor: zoom-in;
}
.cookie-consent-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 15px;
  text-align: center;
  background: #f96332;
}
.cookie-consent-text {
  color: #fff;
  font-weight: 500;
}
.cookie-consent-text span {
  cursor: pointer;
  text-decoration: underline;
}
.cookie-consent-text span:hover {
  color: #333;
}
.cookie-consent-btn {
  background: #f4f5f6;
  color: #333;
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  text-decoration: none !important;
  margin: 0 10px;
}
.cookie-consent-btn:hover {
  background: #dedede;
}
.desc-content-innerHtml {
  font-weight: 400 !important;
  white-space: pre-wrap;
}
.not-founnd-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
}
.not-founnd-content {
  font-size: 16px;
}
.relativ-v-align {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.relative-pos {
  position: relative;
}

.fx-center-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.doc-type-label {
  max-width: 355px;
}
.error-wrapper {
  font-size: 12px;
  padding: 5px 0;
  display: block;
}
.fx-space {
  display: flex;
  justify-content: space-between;
  padding-right: 25px !important;
  padding-left: 25px !important;
  margin-bottom: 20px;
  margin-top: 20px;
}
.fx-space-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fx-column-justify-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fx-space-center .btn {
  padding: 5px 10px;
}
.center-helper {
  text-align: center;
}
.orange-bg {
  background: #f96332;
  color: #fff !important;
  font-weight: 500;
}
.orange-label {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 2px 5px;
  font-weight: 500;
  background: #f96332;
  text-transform: capitalize;
  margin: 0 5px;
}
.green-label {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 2px 5px;
  font-weight: 500;
  background: #99a83d;
  text-transform: capitalize;
  margin: 0 5px;
}
.red-label {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 2px 5px;
  font-weight: 500;
  background: #d3391a;
  text-transform: capitalize;
  margin: 0 5px;
}
.white-bg-border {
  border: 1px solid #f96332 !important;
  color: #333 !important;
  font-weight: 500;
  background: #fff !important;
}
.white-bg-border:hover {
  background: #f96332 !important;
  color: #fff !important;
  border: 1px solid #f96332 !important;
}
.orange-bg:hover {
  background: #db5527;
}
.orange {
  color: #f96332 !important;
}
.dark-orange {
  color: #d3391a !important;
}
.dark-red {
  color: #d3391a !important;
}
.gray {
  color: #708295 !important;
}
.gray-pointer {
  color: #708295 !important;
  cursor: pointer;
}
.gray-pointer a {
  color: #708295;
}
.gray-sm {
  color: #708295 !important;
  font-size: 12px;
}
.dark-gray {
  color: #6d6c6a !important;
}
.yellow-bg-gradient {
  background: rgb(253, 204, 93) !important;
  background: linear-gradient(
    50deg,
    rgba(253, 204, 93, 1) 0%,
    rgba(252, 178, 83, 1) 80%
  ) !important;
}
.orange-bg-gradient {
  background: rgb(252, 139, 86) !important;
  background: linear-gradient(
    50deg,
    rgba(252, 139, 86, 1) 0%,
    rgba(250, 106, 56, 1) 80%
  ) !important;
}
.white {
  color: #fff !important;
}
.light-gray {
  color: #d8d8d8 !important;
}
.no-pointer-events {
  pointer-events: none;
}
.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.btn {
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.btn-small {
  font-weight: 500;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
}
.btn-big {
  text-align: center;
  padding: 14px 28px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
button.btn-big {
  border: none;
  box-shadow: none;
}
.clear-btn {
  padding: 12px;
}

.pointer {
  cursor: pointer;
}
.pointer-orange-hover {
  cursor: pointer;
}
.pointer-orange-hover:hover {
  color: #f96332;
}

.underline {
  text-decoration: underline;
}

.logo img {
  width: 100%;
  cursor: pointer;
}
.link-btn {
  color: #708295;
  text-decoration: underline;
  background: no-repeat;
  border: none;
  cursor: pointer;
  display: inline-block;
}
.light-green-bg {
  background: #99a83d;
  color: #fff;
  font-weight: 500;
}

.light-green {
  color: #99a83d;
}

.dark-green {
  color: #517820;
}

.super-light-green-bg {
  background: #e3e7c7;
}

.red {
  color: red;
}
.dark-green-bg {
  background: #517820;
  color: #fff;
  font-weight: 500;
}
.yellow-bg {
  background: #fdcc5d;
  color: #fff;
  font-weight: 500;
}
.gray-bg {
  background: #708295;
  color: #fff;
  font-weight: 500;
}
.light-gray-bg {
  background: #f4f5f6;
}
.gray-bg:hover {
  background: #7d7d7d;
}
.datepicker-button {
  height: 36px !important;
  width: 80px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  cursor: pointer;
}
.m-l-10 {
  margin-left: 10px;
}
.transparent-bg {
  background: #fff;
  color: #708295;
  font-weight: 500;
}
.transparent-bg:hover {
  color: #f96332;
  text-decoration: underline;
}
.white-bg {
  background: #fff;
  color: #333;
  font-weight: 500;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.white-bg:hover {
  background: #dedede;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700 !important;
}

.inline {
  display: inline-flex !important;
  white-space: nowrap;
  flex-wrap: wrap;
}

.block-span {
  display: block;
}
.inline-block {
  display: inline-block !important;
}
.m-l-5 {
  margin-left: 5px;
}
.small-text {
  font-size: 12px !important;
  line-height: inherit !important;
}
.inner-small-text {
  font-size: 12px !important;
  line-height: inherit !important;
  margin-bottom: 10px;
}
.no-rate {
  color: #f96332;
}
.white-box {
  background: #fff;
  color: #333;
  padding: 25px 30px;
  display: inline-block;
  width: 100%;
  position: relative;
}
.white-box .big-p {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
.action-link-orange {
  font-size: 14px;
  color: #f96332;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-black {
  font-size: 14px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-orange-sm {
  font-size: 12px;
  color: #f96332;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-orange:hover,
.action-link-orange-sm:hover,
.action-link-black:hover {
  color: #db5527;
  text-decoration: underline;
}
.action-link-gray {
  font-size: 14px;
  color: #708295;
  text-decoration: none;
  cursor: pointer;
  word-break: break-word;
}
.action-link-gray:hover {
  color: #f96332;
  text-decoration: underline;
}
.medium-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}
.small-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}
.big-title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  width: 35%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 49px;
}
.big-title i {
  font-size: 20px;
  vertical-align: middle;
  cursor: pointer;
}
.big-title i:hover {
  color: #f96332;
}
.inner-label {
  color: #708295;
  margin-right: 5px;
  display: inline-block !important;
}
.inner-label-block {
  color: #708295;
  margin-right: 5px;
  display: block !important;
}
.switch-check-list input.checkbox-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 45px;
  height: 10px;
  background-color: #efefef;
  border: 2px solid #efefef;
  border-radius: 50px;
  cursor: pointer;
}
.switch-check-list input.checkbox-switch:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -3px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  background: #f96332;
}
.switch-check-list input.checkbox-switch:checked:after {
  left: 26px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}
.editable-input {
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 10px;
}
.info-row-editable .editable-input {
  padding: 5px;
  text-align: center;
  height: 30px;
}
.order-details-box-left-body div {
  line-height: 30px;
}
.editable-input:disabled {
  background: #fff;
  color: #708295 !important;
}
.edit-no-input {
  width: 50px;
}
.edit-calendar-input {
  width: 90px;
}
.editable-input-action i {
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #708295;
  margin-left: 5px;
}
.editable-input-action i:hover {
  color: #f96332;
}
.mat-button-wrapper svg {
  width: 18px !important;
  color: #708295;
}
.editable-group {
  position: relative;
}
.editable-group-fluid {
  position: relative;
}
.editable-group-fluid .editable-input-action {
  position: absolute;
  top: 0;
  right: -25px;
  border-left: 1px dashed #708295;
  height: 100%;
}
.editable-group-fluid .editable-input-action i {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(5px, -50%);
  transform: translate(5px, -50%);
}
.editable-group .editable-input-action {
  position: absolute;
  top: 0;
  right: 25px;
  border-left: 1px dashed #708295;
  height: 100%;
}
.editable-group .editable-input-action i {
  position: absolute;
  top: 50%;
  transform: translate(5px, -50%);
}
.no-padding {
  padding: 0 !important;
}
.no-padding .white-box-inner-header {
  padding: 20px 30px;
}
.no-padding .white-box-inner-body {
  padding: 15px 30px;
}

.editable-group-row {
  margin-bottom: 10px !important;
}
.editable-group .editable-group-row:last-child {
  margin-bottom: 0;
}
.last-elem {
  margin-bottom: 0 !important;
}
.advanced-search-input input {
  background: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 25px 10px 40px;
  height: 45px;
  line-height: 45px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-out;
}
.gray-search-bg .advanced-search-input input {
  background: #f0f0f0;
  box-shadow: none;
}
.toggle-search-btn {
  margin-right: 15px;
  color: #f96332;
  font-weight: 500;
  cursor: pointer;
  font-size: 12px;
}
.advanced-search-input .icon-inside-input {
  margin-right: 15px;
}
.toggle-search-btn:hover {
  text-decoration: underline;
  color: rgba(249, 99, 50, 0.8);
}
.icon-inside-input {
  display: inline-block;
  position: relative;
}
.icon-inside-input i {
  position: absolute;
  top: 10px;
  font-size: 18px;
  color: #708295;
  left: 10px;
}
.advanced-search-wrapper {
  position: relative;
}
.small-dashboard-body-action .toggle-advanced-search {
  top: 41px;
}
.toggle-advanced-search {
  margin-top: auto;
  position: absolute;
  top: 47px;
  background: #fff;
  z-index: 1;
  width: 100%;
  padding: 0 15px 15px;
  left: 0;
  box-shadow: 1px 6px 12px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  min-width: 440px;
}
.advance-search-row {
  display: inline-table;
  margin: 0 15px;
  min-width: 175px;
  padding: 15px 0 0;
}
.calendar-clear-inline {
  line-height: 45px;
  height: 45px;
}
.advance-search-row .calendar-input-control {
  background: #f0f0f0 !important;
}

.advance-search-row label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.elem-page-wrapper {
  margin-left: 15px;
}
.dashboard-wrapper {
  height: 100%;
  padding: 0 0 0 250px;
}
.subheader-title {
  font-size: 25px;
  font-weight: 700;
  float: left;
  text-transform: capitalize;
}

.subheader-actions {
  float: right;
  display: flex;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}
.xxx {
  position: absolute;
  top: 0;
  background: #f96332;
  color: #fff;
}

/*review offers */
.review-box-wrapper .table-action {
  min-width: 160px;
  padding: 12px;
}
.review-box-wrapper .table-action ul li {
  text-align: center;
}
.review-box-wrapper {
  display: inline-block;
  width: 100%;
  padding: 25px 0;
  border-top: 1px solid #eaeaea;
  font-size: 0;
}
.review-box-left {
  width: 25%;
  float: left;
  font-size: 14px;
}
.review-box-right {
  width: 75%;
  float: left;
  font-size: 14px;
}
.review-box-left img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 5px;
}
.review-date {
  font-size: 12px;
  margin-top: 7px;
}
.review-box-wrapper .big-p {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 5px;
}
.review-body {
  color: #6d6c6a;
  /* margin-bottom: 15px; */
}
.view-more {
  font-size: 12px;
  cursor: pointer;
  color: #f96332;
  font-weight: 500;
  margin-top: 10px;
}
.view-more:hover {
  text-decoration: underline;
}
.children-comments-wrapper {
  display: inline-block;
  width: 100%;
}
.review-lvl0 .children-comments-wrapper {
  padding-left: 20px;
}
.children-comments-wrapper .review-box-wrapper {
  border-top: 0;
  padding: 25px 0 0;
}
.review-lvl1-plus .children-comments-wrapper {
  padding-left: 0;
}
.review-offer-wrapper .white-box-header {
  border: none;
  padding: 0;
}
.review-offer-wrapper .star {
  position: relative;
  display: inline-block;
  font-size: 25px !important;
  color: #d8d8d8 !important;
  line-height: 25px;
  width: 25px;
  height: 25px;
}
.review-offer-wrapper .full {
  color: #f96332 !important;
}
.review-offer-wrapper .half {
  color: #f96332 !important;
}
.review-offer-wrapper app-rating {
  display: inline-block;
  margin-bottom: 15px;
}
.review-offer-action {
  font-size: 18px;
  margin: 0 5px;
  cursor: pointer;
}
.no-reviews {
  font-size: 15px;
  padding-top: 20px;
  border-top: 1px solid #eaeaea;
  font-weight: 500;
}
/* end review offers */

/* service offer */
.custom-price {
  margin-top: 15px;
}
.card-price-wrapper {
  border-top: 2px solid #eaeaea;
  margin: 15px -15px 0;
  padding: 15px 15px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-price {
  float: left;
  display: flex;
  flex-direction: column;
  line-height: 20px;
}
.old-price {
  color: #f96332;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 10px;
}
.discount {
  color: #f96332;
  font-weight: 500;
  margin-right: 10px;
}
.new-price {
  color: #333;
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.bundle-offers {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 20px;
}
/* end service offer */

/* RFQ */
.rfq-column-background {
  padding: 20px 0 20px 0 !important;
  background: gainsboro;
  display: flex !important;
  justify-content: center;
}

.rfq-column-title {
  /* color: #f96332; */
  font-weight: 700;
  font-size: 22px;
}

.rfq-offer-footer {
  margin-top: 15px;
}

.rfq-qty-column {
  width: 100px !important;
}

.rfq-qty-column input {
  width: 80px !important;
  margin-bottom: 0px;
}

.rfq-service-name-column {
  width: 300px !important;
}

.rfq-service-name-column input {
  width: 280px !important;
  margin-bottom: 0px;
}

.table-wrapper .rfq-table tbody tr td {
  border-bottom: 1px solid #f0f0f0;
  padding: 13px !important;
}

/* .criteria-label {
  margin-bottom: 25px !important;
} */

.service-search-txt {
  text-align: center;
  font-size: 16px;
  font-style: italic;
  top: 50%;
  align-items: center;
  justify-content: center;
}
.rfq-edit-actions-btn {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
}

.rfq-edit-actions-btn .btn-small,
.rfq-edit-actions-btn .btn {
  margin-left: 10px;
}

.rfq-edit-actions {
  display: flex;
  flex-direction: column;
}
.rfq-edit-actions .m-t-calendar-icon {
  margin-top: 30px !important;
}
.rfq-edit-actions div {
  margin: 0 !important;
}
/* .rfq-small-box-details {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
}
.rfq-small-box-details div {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
} */

#rfqDetailsSelect div {
  margin-bottom: 0 !important;
}

.rfq-edit-actions .form-group.timepicker .ngb-tp-hour,
.rfq-edit-actions .form-group.timepicker .ngb-tp-minute {
  margin: 0;
}

/* .rfq-details-subtitle {
  margin-bottom: 10px;
} */

.rfq-invite-modal {
  overflow-y: scroll;
  position: relative;
  height: 27rem;
}

.medium-title-rfq {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
}

.rfq-modal {
  height: 30rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.table-wrapper .rfq-table table thead tr th {
  padding: 10px 4px;
}
.table-wrapper .rfq-table table thead th {
  font-size: 13px;
}

.table-wrapper .star {
  position: relative;
  display: inline-block;
  font-size: 20px !important;
  color: #d8d8d8;
  line-height: 16px;
  width: 16px;
  height: 16px;
}
.table-wrapper .full {
  color: #f96332;
}
.table-wrapper .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}

.rfq-details-row {
  display: inline-block;
  width: 100%;
  border-top: 1px dashed #f0f0f0;
  padding: 15px 0;
}
.rfq-details-row span {
  white-space: pre-line;
}
.rfq-details-row .table-wrapper {
  margin-bottom: 15px;
}
.rfq-details-row:first-child {
  padding: 0;
  border: none;
}
.rfq-details-row:last-child {
  padding-bottom: 0;
}
.rfq-qty {
  margin: 5px 0;
}

.rfq-row-subtitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}

/* .rfq-create {
  margin-bottom: 0px;
  padding: 10px 0px;
  margin-top: 15px;
} */

/* .rfq-create-btn {
  padding: 10px 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
} */

.quotation-row {
  display: flex;
  flex-direction: column;
  height: auto;
  line-height: 40px;
  padding-bottom: 15px;
}

.quotation-toggle-row {
  position: absolute;
  left: -20px;
  top: -3px;
  cursor: pointer;
}

.quotation-toggle-row i {
  font-size: 20px;
}

.quotation-toggle-row.sub-is-open i:before {
  content: "\e819" !important;
}

.quotation-toggle-row:hover {
  color: #f96332;
}

.quotation-name {
  padding: 0px 10px;
}

.quotation-table {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 25px;
  margin-top: 10px;
}

.quotation-actions {
  font-size: 22px;
  margin: 0px 5px;
}

.quotation-bulk .bulk-action-wrapper {
  padding: 8px 30px;
}

.set-commission-btn {
  font-size: 13px;
}

.set-commission-btn .btn {
  margin: 0 15px;
  color: #f96332;
  font-weight: 500;
  cursor: pointer;
  font-size: 13px;
  padding: 0px;
}

.rfq-range-qty {
  background: #f0f0f0;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 35px !important;
  float: left;
  font-size: 14px;
  border-radius: 4px;
}

.rfq-range button {
  line-height: 35px !important;
}

label.mat-radio-label {
  display: flex !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f96332 !important;
}
.mat-radio-container {
  width: 16px !important;
  height: 16px !important;
}
.mat-radio-outer-circle {
  width: 16px !important;
  height: 16px !important;
  border-color: #708295 !important;
}
.mat-radio-inner-circle {
  width: 16px !important;
  height: 16px !important;
  background: #f96332 !important;
}
.left-filter-radio .mat-radio-button {
  display: block;
  margin-bottom: 15px;
}
.left-filter-radio .mat-radio-button:last-child {
  margin-bottom: 0;
}

.left-filter-radio label {
  margin-bottom: 0;
}
.commission-type {
  margin-left: 12px;
  margin-right: 12px;
}

.quotation-total {
  font-size: 20px;
  font-weight: bold;
}

.quotation-total div:last-child {
  display: flex;
  flex-direction: column;
  text-align: right;
}

/* .rfq-modal-create {
  overflow-y: scroll;
  height: 30rem;
  overflow-x: hidden;
} */

.rfq-service-header {
  /* display: inline-block;
  width: 100%; */
}

.rfq-expats-qty {
  margin: 5px 0;
  display: inline-block;
}

.rfq-expats-qty .cart-item-details-range {
  margin: 0 15px;
}

.rfq-service-desc textarea {
  width: 100%;
  height: 5rem;
  line-height: 1.5;
}

.rfq-service-desc-readonly {
  margin-bottom: 15px;
  white-space: pre-line;
}

/* .extension-container {
  white-space: normal !important;
  display: flex;
  flex-direction: column;
}

.extension-container span {
  margin-bottom: 10px;
}

.extension-container span:first-child {
  color: #517820;
  font-weight: bold;
} */

/* .rfq-new-offer {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 15px;
}

.rfq-new-offer span {
  margin-bottom: 5px;
}

.rfq-new-offer span span {
  font-weight: 600;
  margin-right: 5px;
} */

.decline {
  line-height: 1.3 !important;
  height: 6rem !important;
}

/* .rgfq-underline:hover {
  text-decoration: underline;
} */

.faded-checkbox {
  opacity: 0.6;
}

/* .rfq-calendar-wrapp .range-calendar-wrapper {
  left: 0;
} */
/* end RFQ */

label.mat-radio-label {
  display: flex !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f96332 !important;
}

.mat-radio-container {
  width: 16px !important;
  height: 16px !important;
}
.mat-radio-outer-circle {
  width: 16px !important;
  height: 16px !important;
  border-color: #708295 !important;
}
.mat-radio-inner-circle {
  width: 16px !important;
  height: 16px !important;
  background: #f96332 !important;
}

.field-message {
  font-size: 10px;
  padding-left: 4px;
  color: red;
}

.shadow {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}
.dashboard-body {
  padding: 30px 45px;
  clear: both;
  display: inline-block;
  width: 100%;
  margin-top: 81px;
}
.dashboard-body-top {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  height: 41px;
}
.dashboard-body-top .btn {
  margin-left: 15px;
  transition: all 0.3s ease-out;
}
.dashboard-body-top .big-title {
  transition: all 0.3s ease-out;
}
.dashboard-body-action {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  height: 45px;
}
.dashboard-body-action .btn {
  margin-left: 15px;
  transition: all 0.3s ease-out;
}
.subheader-actions > * {
  display: inline-block;
  margin: 0 15px;
}
.subheader-actions > *:last-child {
  margin-right: 0;
}
.btn i {
  margin-right: 3px;
}
.select-group-block label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.select-group-inline label {
  font-weight: 500;
  margin: 0 10px;
  display: inline-block;
}
.select-group-inline ng-select.customiz-select {
  display: inline-block;
}
.add-subcategory i {
  font-size: 24px;
  color: #708295;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.add-subcategory {
  display: inline-block;
  position: relative;
  right: 50px;
}

.card-title-cms {
  margin-bottom: 70px !important;
}
.uplaod-photo-box {
  height: 130px;
  width: 130px;
  border: 1px dashed #708295;
  border-radius: 4px;
  float: left;
}
.uplaod-photo-box img {
  object-fit: cover;
  width: 130px;
  height: 130px;
  padding: 10px;
}
.upload-photo-wrapper .upload-files {
  height: 130px;
  margin: 0;
  line-height: 130px;
  float: left;
  margin-left: 15px;
}
.review-nr {
  color: #f96332;
  margin: 0 12px;
  font-size: 13px;
}

.rating {
  display: flex;
  align-items: center;
}

.rating .star {
  font-size: 20px !important;
  width: 20px;
  height: 18px;
  line-height: 18px;
}

.rating .full {
  color: #f96332 !important;
}
.rating .half {
  color: #f96332 !important;
}

/* header */
.header-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  background: #fff;
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.09);
  font-size: 0;
}
.header-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: #efefef;
  top: 70px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-wrapper-no-bg {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 0;
}
.header-wrapper-no-bg .header-top {
  padding: 15px 45px;
  border-bottom: 1px solid #efefef;
}

.notification-title {
  font-size: 14px;
  font-weight: 800;
}

.notification-body {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
}

.messaging-body {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-empty {
  padding: 15px;
  font-weight: 500;
  background: #fff;
}
.notification-count {
  position: absolute;
  top: -7px;
  right: 15px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-size: 11px;
  font-weight: 500;
}
.side-menu-badge {
  position: absolute;
  top: 0px;
  right: 15px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-size: 11px;
  font-weight: 500;
}
.notification-item {
  position: relative;
  padding: 0 20px;
  cursor: pointer;
}
.notification-item i {
  color: #708295;
  font-size: 24px;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.notification-item i:hover {
  color: #f96332;
}
.header-top {
  width: 100%;
  display: inline-block;
  /* border-bottom: 1px solid #EFEFEF; */
  padding: 10px 0;
  font-size: 14px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.logo,
.header-top-action-wrapper,
.header-top-user {
  height: 50px;
}
.header-top-notifications,
.header-top-user-info,
.header-top-action-no-logged {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.logo img {
  width: 150px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.header-top-user-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-top-user-info {
  text-align: right;
  margin-left: 35px;
}
.header-top-user-img {
  margin-left: 15px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header-top-menu {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #708295;
  font-size: 18px;
  cursor: pointer;
  margin-left: 5px;
}
.header-top-menu:hover {
  color: #f96332;
}
.header-bottom ng-select.customiz-select .ng-select-container {
  height: 35px !important;
  line-height: 35px;
}
.header-bottom
  ng-select.customiz-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
.header-bottom
  ng-select.customiz-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  height: 35px;
  line-height: 35px;
  padding: 0 15px 0 25px;
}
.header-bottom
  ng-select.customiz-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  height: 35px;
  line-height: 35px;
}
.search-box,
.location-select {
  position: relative;
}
.search-box input {
  background: #f0f0f0;
  border: none;
  padding: 0 15px 0 35px;
  width: 100%;
  border-radius: 4px;
  color: #333;
  height: 35px;
  line-height: 35px;
  font-size: 13px;
}
.search-box:before {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #708295;
  content: "\e813";
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  /* font-size: 120%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
ng-select.location-select:before {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #708295;
  content: "\e822";
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  /* font-size: 120%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  z-index: 1002;
}
.header-bottom ng-select.customiz-select {
  margin-bottom: 0;
}
.header-bottom {
  display: inline-block;
  width: 100%;
  padding: 20px 0;
  background: #f96332;
  font-size: 14px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-bottom-action-item {
  margin-left: 15px;
}
.main-nav-wrapper .breadcrumbs {
  height: 35px;
  line-height: 35px;
}
.main-nav-wrapper .breadcrumbs ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.main-nav-wrapper .breadcrumbs ul li {
  display: inline;
  color: #cacaca;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}
.main-nav-wrapper .breadcrumbs ul li:hover {
  color: #333;
}
.breadcrumbs-item-group {
  display: inline-block;
}
.hide-breadcrumbs {
  display: none;
}
.breadcrumbs-item {
  color: #708295;
  font-weight: 500;
  padding-right: 10px;
  text-transform: capitalize;
  word-break: break-word;
}
.breadcrumbs-item:hover {
  color: #f96332;
}
.breadcrumbs-separator {
  color: #708295;
  padding-right: 10px;
}
.breadcrumbs-item-wrapper {
  margin-bottom: 15px;
}
.inner-breadcrumbs-item-wrapper {
  display: inline-block;
}
.inner-breadcrumbs-item-wrapper .breadcrumbs-separator {
  display: inline-block;
  color: #333;
}
.main-nav-wrapper .breadcrumbs ul li:hover::after {
  color: #cacaca;
}
.main-nav-wrapper .breadcrumbs ul li.active-breadcrumbs {
  color: #333;
}
.main-nav-wrapper .breadcrumbs ul li:after {
  content: "/";
  padding: 15px;
}
.main-nav-wrapper .breadcrumbs ul li.active-breadcrumbs::after {
  display: none;
}
.main-nav-wrapper .breadcrumbs ul li.active-breadcrumbs:before {
  content: "";
  height: 5px;
  width: 100%;
  background: #f96332;
  position: absolute;
  bottom: -29px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/* overlay header */
.header-overlay-wrapper .mat-menu-content {
  padding: 0 !important;
}
.no-item-overlay {
  color: #708295;
}
.header-overlay-wrapper {
  border-radius: 4px !important;
  width: 340px !important;
  max-width: 340px !important;
}
.header-overlay-top {
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
}
.header-overlay-img {
  float: left;
}
.header-overlay-item-body {
  float: left;
  margin-left: 10px;
  width: calc(100% - 70px);
}
.header-overlay-item-body .cart-item-details-range-wrapper {
  float: right;
}
.header-overlay-img img {
  width: 60px;
  object-fit: cover;
  height: 60px;
  border-radius: 4px;
}

.messaging-header-overlay-img img {
  width: 50px;
  object-fit: cover;
  height: 50px;
  border-radius: 25px;
}

.messaging-header-overlay-item {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  width: 100%;
}

.header-overlay-item {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  display: inline-block;
  width: 100%;
}
.header-overlay-bigtxt {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 16px;
}
.header-overlay-bottom {
  padding: 15px;
  /* position: fixed; */
  background: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}
.header-overlay-body {
  font-size: 0;
  max-height: 370px;
  overflow: auto;
  display: block;
}
.header-overlay-price {
  font-size: 15px;
  font-weight: 600;
}
.header-overlay-old-price {
  font-size: 12px;
  font-weight: 500;
  color: #f96332;
  float: left;
  margin-left: 7px;
}
.line-through {
  text-decoration: line-through;
}

.header-overlay-price-wrapper {
  float: left;
  line-height: 25px;
}
.header-overlay-total-cart-items {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.header-overlay-total-cart-price {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}
.header-overlay-total-cart {
  margin-bottom: 7px;
  border-bottom: 1px dashed #eaeaea;
  padding-bottom: 7px;
}
.header-overlay-checkout {
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.header-overlay-checkout-cart {
  padding: 10px;
  margin-top: 15px;
}
/* end overlay header */

/* dashboard statistics */
.chart-wrapper {
  width: 100%;
  height: 300px;
  min-height: 300px;
  position: relative;
}
.chart-wrapper-auto-width {
  width: auto !important;
}
.label-inside-doughnut-chart {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: 12px;
}
.label-inside-doughnut-chart span {
  display: block;
  font-size: 20px;
  font-weight: 600;
}
.dashboard-statistics-box {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  font-size: 0;
  position: relative;
  height: 300px;
  z-index: 2;
  /* overflow: hidden; */
}
.dashboard-statistics-box-title {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  float: left;
}
.dashboard-statistics-box-header-action {
  float: right;
  min-width: 150px;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.dashboard-statistics-box-right-legend {
  float: left;
  margin-left: 30px;
  position: relative;
  height: 300px;
}
.dashboard-statistics-box-right-legend-content {
  float: left;
  margin-left: 30px;
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}
.dashboard-statistics-box-right-legend-vertical-title {
  float: left;
  font-size: 16px;
  font-weight: 600;
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}
.dashboard-statistics-box-right-legend-content p {
  margin-bottom: 7px;
  font-weight: 500;
  position: relative;
}
span.dashboard-statistics-box-right-legend-point {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 0 !important;
  top: 1px;
}
.dashboard-statistics-box-right-legend-content p span {
  margin-left: 21px;
}
.dashboard-statistics-box-header {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #efefef;
  height: 50px;
  line-height: 50px;
  position: absolute;
  left: 0;
  padding: 0 15px;
  background: #fff;
  z-index: 2;
}
.date-filter-select {
  margin-bottom: 0 !important;
}
.dashboard-statistics-box-subtitle {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 14px;
}
.dashboard-statistics-box-body {
  height: 300px;
  overflow-y: auto;
  padding: 50px 15px 0;
  overflow-x: hidden;
  font-size: 0;
}
.dashboard-statistics-box-body:last-child {
  margin-bottom: 0;
}
.dashboard-statistics-box-empty {
  padding: 15px 0;
  font-weight: 500;
}
.dashboard-statistics-box-body-list-item span {
  font-weight: 500;
}
.dashboard-statistics-box-body-list-item {
  margin-bottom: 7px;
}
.sts-table-wrapper {
  height: 250px;
  overflow: auto;
  padding-top: 50px;
}
.statistics-table {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  height: 300px;
}
.statistics-table table {
  width: 100%;
  border-collapse: collapse;
}
.statistics-table table thead tr th {
  border-bottom: 1px solid #f6f6f6;
  padding: 15px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.statistics-table table tbody tr td {
  border-bottom: 1px solid #f6f6f6;
  padding: 15px;
}
.dashboard-statistics-total-box {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.dashboard-statistics-box
  ng-select.customiz-select.ng-select
  .ng-clear-wrapper
  .ng-clear,
.table-header-action-wrapper
  ng-select.customiz-select.ng-select
  .ng-clear-wrapper
  .ng-clear {
  line-height: 35px !important;
}
.dashboard-statistics-box ng-select.customiz-select .ng-select-container,
.table-header-action-wrapper ng-select.customiz-select .ng-select-container {
  height: 35px !important;
  border: none;
  background: #f0f0f0;
  border-radius: 4px !important;
  line-height: 35px !important;
}
.dashboard-statistics-box
  ng-select.customiz-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
.dashboard-statistics-box
  ng-select.customiz-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input,
.table-header-action-wrapper
  ng-select.customiz-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
.table-header-action-wrapper
  ng-select.customiz-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 15px 0 25px;
  height: 35px !important;
  line-height: 35px !important;
}
.dashboard-statistics-box
  ng-select.customiz-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input,
.table-header-action-wrapper
  ng-select.customiz-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  font-size: 13px;
  height: 35px !important;
  line-height: 35px !important;
  padding: 0;
}
.dashboard-statistics-box
  ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input,
.table-header-action-wrapper
  ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 20px 0 15px !important;
  height: 35px;
  line-height: 35px;
}
.statistics-inner-box-wrapper,
.statistics-box-row {
  clear: both;
  display: inline-block;
  width: 100%;
  font-size: 0;
}
.statistics-box-row span {
  font-size: 14px;
}
.statistics-box-row {
  height: 44px;
  line-height: 44px;
  border-bottom: 1px dashed #efefef;
}
.statistics-inner-box-wrapper {
  border-bottom: 1px solid #efefef;
}
.statistics-compare-label {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 12px;
  color: #708295;
  background: #fff;
  padding: 1px 5px;
  white-space: nowrap;
}
.statistics-compare-label-wrapper {
  position: relative;
  height: 0;
  width: 100%;
}
.statistics-compare-label-wrapper.sts-last-child {
  display: none;
}
.statistics-inner-box-wrapper.sts-last-child {
  border: none;
  margin: 0;
  padding: 0;
}
.statistics-inner-box-list-wrapper .sts-last-child {
  border: none;
}
.statistics-inner-box-list-wrapper .statistics-box-row:last-child {
  border: none;
}
.dashboard-statistics-chart-wrapper {
  width: 100%;
  height: 240px;
  position: relative;
  overflow: hidden;
}
.table-header-action-wrapper {
  position: relative;
}
.statistics-tab-wrapper {
  font-size: 14px;
  position: relative;
}
.statistics-tab-wrapper .tab-item {
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  padding: 0 5px;
  position: relative;
  color: #708295;
}
.statistics-tab-wrapper .tab-item:hover {
  color: #f96332;
}
.active-sts-tab:before {
  content: "";
  height: 2px;
  background: #f96332;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.active-sts-tab {
  color: #333 !important;
}
.tab-r-t-l .tab-item {
  margin-left: 10px;
}
.tab-l-t-r .tab-item {
  margin-right: 10px;
}
.statistics-box-mask {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  border-radius: 4px;
}
.sts-box-h-50 {
  height: 145px;
}
.sts-box-body-h-50 {
  height: 145px;
}
.sts-title-m-b {
  margin-bottom: 30px;
}
.sts-content-row-m-b {
  margin-bottom: 30px;
}
.dashboard-statistics-wrapper .big-title {
  line-height: 24px;
}
.sts-section {
  margin-bottom: 45px;
}
span.hardcoded {
  background: #99a83d;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
  text-transform: lowercase;
}
.dashboard-statistics-box-header-action-multi {
  float: right;
  height: 50px;
}
.dashboard-statistics-box-header-action-multi .statistics-tab-wrapper {
  margin-right: 15px;
}
.sts-calendar-wrapper {
  font-size: 14px;
  z-index: 999;
}
.sts-calendar-wrapper .calendar-input-control {
  height: 35px;
  line-height: 35px;
  padding: 0;
  font-size: 11px;
  color: #9b9708295b9b;
  font-weight: 500;
  width: 140px;
}
.sts-calendar-wrapper .calendar-input-icon {
  display: none;
}
.sts-calendar-wrapper .toggle-calendar-icon {
  line-height: 50px !important;
  height: 45px;
  width: 20px;
}
.sts-calendar-wrapper .editable-input-calendar {
  float: right;
}
.sts-calendar-wrapper .toggle-calendar-icon {
  display: none;
}

.calendar-white-icon .toggle-calendar-icon {
  background: transparent;
  color: #fff;
}
.statistics-inner-box-wrapper-center-elem.ng-star-inserted {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.sts-empty-box {
  font-size: 14px;
  font-weight: 500;
  color: #708295 !important;
  padding: 15px 0;
}
/* end dashboard statistics */

.cart-steps-header ul li a.active {
  color: #333 !important;
  position: relative;
}
.cart-steps-header ul li a.active:before {
  content: "";
  height: 5px;
  width: 110%;
  background: #f96332;
  position: absolute;
  bottom: -20px;
  left: -8px;
}

.cart-step-box {
  padding: 20px 30px;
  border: 2px solid #eaeaea;
  border-radius: 6px;
  font-size: 0;
  margin-bottom: 25px;
}
.cart-step-box-wrapper {
  padding: 30px 30px;
}
.cart-step-box-header {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
}

.update-qty {
  padding: 2px 6px;
  font-size: 12px;
}
.cart-box-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}
.cart-box-address p {
  font-size: 14px;
  margin-bottom: 5px;
}
.cart-box-address-add {
  color: #f96332;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  margin: 15px 0 30px 0;
  cursor: pointer;
}
.cart-box-address {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
}
.cart-box-right-total span {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-right: 10px;
}
.cart-box-right-total {
  font-size: 22px;
  color: #f96332;
  font-weight: 700;
  line-height: 22px;
}
.cart-step-box .cart-box-item-wrapper:last-child {
  border: none;
}
.header-top-action-no-logged a {
  margin-left: 30px;
}
.header-top-lang {
  margin-left: 15px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header-top-lang {
  display: none;
}
#header-wrapper-admin {
  position: fixed;
  background: #f4f5f6;
  right: 0;
  top: 0;
  width: calc(100% - 250px);
  z-index: 98;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
#header-wrapper-market {
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 98;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-breadcrumbs {
  float: left;
  height: 50px;
  line-height: 50px;
}
.header-bottom-action {
  width: calc(100% - 200px);
}
.search-box-header-wrapper {
  width: calc(100% - 300px);
}
.service-categ.btn {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  padding: 8px 20px 8px 15px;
  width: 182px;
}
/* .leaf-category-title {
  border-bottom: 1px solid #f0f0f0;
} */

.category-breadcrumb {
  list-style-position: inside;
  padding: 0;
  /* border-left: solid 2px rgba(127, 127, 127, 0.3); */
  list-style-type: none;
  /* padding-left: 10px; */
}

.category-breadcrumb li {
  padding-bottom: 5px;
  font-weight: normal;
}

.category-breadcrumb li:last-child {
  color: #f96332;
  padding-bottom: 0px;
}

.small-fixed-header-admin .header-top {
  padding: 0 45px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  height: 51px;
  /* box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2); */
}
.small-fixed-header-market .header-top {
  padding: 0;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.small-fixed-header-market.header-wrapper:before {
  top: 50px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.small-fixed-header-admin .header-top-user-img img,
.small-fixed-header-market .header-top-user-img img {
  width: 40px;
  height: 40px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.small-fixed-header-admin .notification-item i,
.small-fixed-header-market .notification-item i {
  font-size: 20px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.small-fixed-header-admin .notification-count,
.small-fixed-header-market .notification-count {
  width: 20px;
  height: 20px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.small-fixed-header-market .logo img {
  width: 120px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.small-fixed-header-market .header-bottom {
  padding: 10px 0;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.small-fixed-header-market .service-categ.btn {
  padding: 7px 14px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.header-wrapper.small-fixed-header-market {
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.2);
}
.small-fixed-header-market
  .main-nav-wrapper
  .breadcrumbs
  ul
  li.active-breadcrumbs:before {
  bottom: -19px;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/*.fixed-header-on*/
/*    .nav-item-backdrop*/
/*    ~ .cdk-overlay-connected-position-bounding-box {*/
/*    top: 106px !important;*/
/*    -ms-transition: top 0.3s ease-out;*/
/*    -moz-transition: top 0.3s ease-out;*/
/*    -webkit-transition: top 0.3s ease-out;*/
/*    -o-transition: top 0.3s ease-out;*/
/*    transition: top 0.3s ease-out;*/
/*}*/
/*.cdk-overlay-connected-position-bounding-box {*/
/*    height: auto !important;*/
/*    width: auto !important;*/
/*}*/
/* end header */

/* fixed elem */
.details-navigation {
  height: 41px;
}
.small-app-nav {
  position: fixed;
  top: 51px;
  z-index: 999;
  width: calc(100% - 340px) !important;
  background: #f4f5f6;
  padding: 5px 0;
  margin-bottom: 0;
  height: 41px;
}
.small-app-nav .big-title {
  font-size: 20px;
  line-height: 30px;
}
.small-app-nav .details-navigation .mat-tab-group.mat-primary .mat-ink-bar,
.small-app-nav .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  height: 2px;
}
.small-app-nav .mat-ink-bar {
  bottom: 5px;
}
.details-navigation .big-title {
  transition: all 0.3s ease-out;
}
.small-dashboard-body-top-0ElemUp {
  top: 51px !important;
}
.small-dashboard-body-top-1ElemUp {
  top: 92px !important;
}
.small-dashboard-body-top-2ElemUp {
  top: 133px !important;
}
.small-dashboard-body-top {
  position: fixed;
  top: 51px;
  z-index: 999;
  width: calc(100% - 340px) !important;
  background: #f4f5f6;
  padding: 5px 0;
  margin-bottom: 0;
  height: 41px;
}
.small-dashboard-body-top .big-title {
  font-size: 20px;
  line-height: 30px;
}
.small-dashboard-body-top .btn {
  padding: 5px 15px;
}
.small-dashboard-body-action {
  position: fixed;
  background: #f4f5f6;
  width: calc(100% - 340px);
  margin-bottom: 0;
  top: 92px;
  padding: 5px 0;
  z-index: 999;
  border-top: 1px solid #efefef;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.07);
  height: 45px;
}
.small-dashboard-body-action .btn {
  padding: 5px 15px;
}
.small-dashboard-body-action ng-select.customiz-select .ng-select-container {
  height: 31px !important;
  line-height: 31px;
}
.small-dashboard-body-action
  ng-select.customiz-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
.small-dashboard-body-action
  ng-select.customiz-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  height: 31px;
  line-height: 31px;
  padding: 0 15px 0 25px;
}
.small-dashboard-body-action
  ng-select.customiz-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  height: 31px;
  line-height: 31px;
}
.small-dashboard-body-action .ng-select .ng-select-container {
  min-height: 31px !important;
}
.small-dashboard-body-action .advanced-search-input input {
  padding: 7px 25px 7px 40px;
  line-height: 31px;
  height: 31px;
}
.small-dashboard-body-action .icon-inside-input i {
  top: 5px;
}
.small-table-bulk {
  position: fixed;
  top: 150px;
  z-index: 999;
  width: calc(100% - 340px) !important;
  background: #f4f5f6;
  padding: 7px;
  margin-bottom: 0;
}
.small-table-bulk .bulk-action-wrapper {
  padding: 5px 15px;
  min-height: 41px;
  line-height: 31px;
}
/* end fixed elem */

/* details page */
.details-2c {
  display: grid;
  grid-template-columns: 280px auto;
  gap: 24px;
}
.small-box {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
}
.small-box-top-action {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background: #fff;
  margin-bottom: -5px;
  border-bottom: 2px solid #f0f0f0;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.small-box-header-img {
  padding: 15px 15px 0;
}
.small-box-header {
  border-bottom: 1px solid #f0f0f0;
  padding: 20px;
}
.small-box img {
  width: 100%;
  border-radius: 4px;
  height: 214px;
  -o-object-fit: cover;
  object-fit: cover;
}
.small-box-details {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.small-box-details div {
  /* white-space: nowrap;
  text-overflow: ellipsis; */
  margin: 10px 0;
}

.small-box-details div:last-child {
  margin-bottom: 0;
}
.small-box-details div:first-child {
  margin-top: 0;
}
.small-box-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.small-box-list ul li {
  border-bottom: 1px solid #f0f0f0;
  padding: 15px 20px;
  /* cursor: pointer; */
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.small-box-list ul li:hover {
  color: #f96332;
}
.small-box-status {
  /* position: absolute;
  top: 20px;
  left: 20px; */
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px 0;
}
.small-box-star .star {
  position: relative;
  display: inline-block;
  font-size: 20px !important;
  color: #d8d8d8;
  line-height: 16px;
  width: 16px;
  height: 16px;
}
.small-box-star .full {
  color: #f96332;
}
.small-box-star .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}

.rating .star {
  position: relative;
  display: inline-block;
  font-size: 20px !important;
  color: #d8d8d8;
  line-height: 16px;
  width: 16px;
  height: 16px;
}
.rating .full {
  color: #f96332;
}
.rating .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}
.small-box-status-rigth {
  /* position: absolute;
  top: 15px;
  right: 10px;
   */
  font-size: 12px;
  margin: 15px;
}
.small-box-subtitle {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px !important;
  color: #708295 !important;
}
.white-box-wrapper {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 0;
  padding: 25px;
}
.service-offer-footer .btn {
  margin-left: 15px;
}
.white-box-body {
  font-size: 14px;
  padding: 25px 0;
}
/* .white-box-wrapper .white-box-row:last-child .white-box-body {
  padding-bottom: 0;
} */
.white-box-body p {
  margin-bottom: 10px;
}

.white-box-body p a {
  color: #333;
}

.white-box-body p a:hover {
  color: #db5527;
  text-decoration: underline;
}

.white-box-body p span {
  font-weight: 600;
  margin-right: 5px;
  display: block;
}

.white-box-header-action {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.white-box-header-action:hover,
.remove-box-item:hover {
  color: #f96332;
}
.white-box-header {
  border-bottom: 1px solid #efefef;
  display: inline-block;
  width: 100%;
  padding: 0 0 20px 0;
}
.no-border {
  border: none !important;
}
.border-b {
  border-bottom: 1px solid #efefef;
}
.fit-content {
  height: fit-content;
}
.p-10 {
  padding: 10px;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.details-navigation,
.details-body-wrapper {
  display: inline-block;
  width: 100%;
}
.order-details-box-link ul {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
}
.order-details-box-link ul li {
  display: block;
  cursor: pointer;
  font-size: 14px;
  color: #f96332;
  cursor: pointer;
  margin-bottom: 5px;
}
.order-details-box-link ul li:hover {
  text-decoration: underline;
}
.details-body-wrapper {
  padding: 20px 0;
}
.order-details-box-wrapper {
  /* border-bottom: 1px solid #eaeaea; */
  font-size: 14px;
  margin-bottom: 20px;
  /* padding-bottom: 20px; */
}
.delete-price-row {
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
}
.delete-price-row i {
  color: #708295;
  font-size: 18px;
}
.delete-price-row i:hover {
  color: #f96332;
}
.delete-price-row i:before {
  margin: 0;
  padding: 0;
}
.added-price-row {
  border-top: 1px solid #efefef;
  padding-bottom: 5px;
  margin-bottom: 20px;
  padding-top: 40px;
  position: relative;
}
/* .order-details-box-wrapper:first-child {
  padding-top: 0;
} */
.order-details-box-left img {
  float: left;
  margin-right: 15px;
  border-radius: 4px;
}
.order-details-box-right-wrapper {
  float: right;
  /* margin-top: 35px; */
}
.order-details-left-content {
  width: calc(100% - 135px);
}
.order-details-box-wrapper .order-details-box-left {
  width: 70%;
}
.order-details-box-wrapper .order-details-box-right-wrapper {
  width: 30%;
}
.order-details-box-left {
  float: left;
}
.order-details-box-bottom {
  padding: 0;
  font-size: 0;
}
.box-bottom-assign-expat {
  margin-top: 10px;
}
.order-details-box-left-header {
  margin-bottom: 15px;
}
/* .order-details-box-left-body {
  margin-bottom: 15px;
} */
.order-details-box-right {
  /* position: relative;
  top: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%); */
  text-align: right;
}
.order-details-box-right {
  text-align: right;
}

.expat-row {
  display: inline-block;
  width: 100%;
  border-top: 1px dashed #eaeaea;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}
.expat-row-info-icon {
  line-height: 30px;
}
.expat-row i {
  width: 11px;
  display: inline-block;
}
.expat-buttons-item i {
  width: auto;
}
.expat-row-info .calendar-input-control {
  padding: 5px;
  height: 30px;
  line-height: 30px;
}
.expat-row-info app-date-range-calendar {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.expat-row-info .toggle-calendar-icon {
  height: 30px;
  line-height: 30px !important;
  font-size: 16px;
  width: 30px;
}
.expat-row-info {
  padding-left: 15px;
  margin-top: 5px;
  line-height: 30px;
  height: 30px;
}
.expat-buttons-item {
  cursor: pointer;
  float: left;
  color: #cacaca;
  font-size: 18px;
  margin-left: 15px;
}
.expat-buttons-item:hover {
  color: #f96332;
}

.small-box-header-img:hover {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-trigger {
  position: absolute;
  font-size: 18px;
  font-weight: 800;
  visibility: hidden;
  cursor: pointer;
}

.small-box-header-img:hover .profile-pic {
  opacity: 0.5;
  cursor: pointer;
}

.small-box-header-img:hover .edit-trigger {
  visibility: visible;
}

.expat-row-title {
  color: #f96332;
  margin-top: 10px;
  font-weight: 500;
  padding-bottom: 5px;
  font-size: 14px;
}

.small-box-header-img:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.orange-border {
  border: 1px solid #f96332;
  padding: 5px 9px;
}
.white-box-footer {
  display: inline-block;
  width: 100%;
  /* padding: 25px 0 0; */
  border-top: 1px dashed #f0f0f0;
  padding-top: 15px;
}
.order-details-box-top {
  position: relative;
  padding: 0;
}
.remove-box-item {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #708295;
  font-size: 18px;
}
.order-details-box-left img {
  width: 120px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
}
/* end details page */

/*categories tree  */
/* .tree-check{
  font-size: 20px;
} */
.tree-check .mat-checkbox-layout {
  align-items: baseline !important;
  vertical-align: middle !important;
  display: inline-flex !important;
  white-space: nowrap !important;
  height: auto !important;
}
.category-tree-group {
  padding-left: 20px;
  display: inline-block;
  width: 100%;
  position: relative;
  font-size: 0;
}
.category-tree-row {
  border-bottom: 1px solid #f0f0f0;
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  margin-left: 15px;
}
.tree-inner-btn {
  color: #f96332;
  font-weight: 500;
  padding: 0;
  cursor: pointer;
  display: block;
  text-decoration: underline;
  margin-right: 10px;
  margin-bottom: 5px;
}
.tree-inner-btn:hover {
  color: #db5527;
}
.category-tree-row span {
  display: inline-block;
  margin: 5px 20px 5px 0;
}
.category-tree-group .toggle-row-action {
  position: absolute;
  left: -5px;
  top: 15px;
  cursor: pointer;
  font-size: 20px;
}
.tree-commissions-row {
  display: flex;
  width: 100%;
  border-top: 1px dashed #f0f0f0;
  padding: 10px 0;
}
.tree-commissions-action {
  margin: 0 10px;
}
.tree-commissions-row-wrapper .tree-commissions-row:first-child {
  border-top: none;
}
.tree-commissions-row div {
  margin-right: 10px;
}
.tree-commissions-action span {
  margin: 0px 15px 0 0;
  cursor: pointer;
  color: #708295;
}
.tree-commissions-action span:hover {
  color: #f96332;
}
/* .category-tree-group .toggle-row-action i:before {
  margin: 0;
  width: auto;
} */
.category-tree-row-periods {
  margin: 0 5px;
  /* border-bottom: 1px solid #F0F0F0; */
  line-height: 35px;
  /* margin-bottom: 10px; */
}

/* #readonly-periods {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 10px;
} */

.category-tree-row-periods span {
  margin-right: 3px;
}

.category-tree-row-periods ul {
  list-style: none;
}
/*end categories tree  */

/* details navigation */
a.mat-tab-link.mat-tab-label-active {
  color: #f96332;
}
.details-navigation .mat-tab-nav-bar {
  border-bottom: 0;
}
.details-navigation .mat-tab-link {
  height: auto;
  padding: 10px;
  width: auto;
  min-width: auto;
  opacity: 1;
  margin-left: 20px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  color: #333;
}
.details-navigation .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f96332;
  height: 4px;
}
.details-navigation .mat-tab-link:hover {
  color: #f96332;
}
/* end details navigation*/

/* table */

/* .table-responsive {
  overflow: visible;
} */
.info-icon-table {
  color: #708295;
  cursor: pointer;
}
.info-icon-table:hover {
  color: #f96332;
  -ms-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.img-inside-table img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border: 1px dashed #708295;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.overlap-action-table {
  overflow: visible;
}
.table-wrapper {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.inner-table-wrapper {
  border: 1px solid #f4f5f6;
}
.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  overflow: visible;
  word-break: normal;
}
.table-action {
  position: absolute;
  top: -15px;
  right: 25px;
  border: 1px solid #efefef;
  padding: 15px;
  background: #fff;
  display: none;
  z-index: 1;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  /* background: #f3f3f3 !important; */
}
.toggle-is-open .table-action {
  display: block;
}
.toggle-is-open.table-action-wrapper i:before {
  transform: rotate(0);
}
.table-action ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.table-action ul li:hover {
  color: #f96332;
  text-decoration: underline;
}
.table-action ul li a:hover {
  color: #f96332;
  text-decoration: underline;
}
.table-action ul li {
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-align: left;
}
.table-action ul li:last-child {
  margin-bottom: 0;
}
.table-wrapper table thead th {
  font-size: 16px;
  font-weight: 500;
}
.table-wrapper table thead tr th {
  border-bottom: 1px solid #ecf2f8;
  padding: 12px;
  text-align: left;
}
.table-wrapper table tbody tr td {
  border-bottom: 1px solid #ecf2f8;
  padding: 12px;
}

.table-wrapper .star {
  position: relative;
  display: inline-block;
  font-size: 20px !important;
  color: #d8d8d8;
  line-height: 16px;
  width: 16px;
  height: 16px;
}
.table-wrapper .full {
  color: #f96332;
}
.table-wrapper .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f96332;
}
.table-wrapper table tbody tr:last-child td {
  border-bottom: none;
}
.table-action-wrapper {
  position: relative;
  display: inline-block;
}
.table-action-wrapper:hover i:before,
.add-subcategory i:hover {
  color: #f96332;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.table-wrapper .mat-checkbox-layout {
  margin-bottom: 0;
}
.table-wrapper table thead th .mat-checkbox-frame {
  border: 1px solid #333 !important;
}
.table-wrapper table thead .checkmark-checkbox {
  border: 1px solid #333;
}
.table-action-wrapper i:before {
  transform: rotate(90deg);
  color: #708295;
  font-size: 20px;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.table-action-btn i:before {
  color: #708295;
  font-size: 19px;
  cursor: pointer;
}
.table-action-btn:hover i:before {
  color: #f96332;
}
.table-action-btn {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  /* right: 10px; */
}
.draggable-table .table-action-btn {
  right: 5px;
}
.inner-table-btn {
  display: inline-block;
  padding: 0 5px;
  cursor: pointer;
  color: #708295;
}
.inner-table-btn i:before {
  font-size: 20px;
}
.inner-table-btn:hover {
  color: #f96332;
}
/* end table */

/* dragable table */
.table-wrapper.draggable-table .mat-table {
  background: transparent;
  font-family: inherit;
}
.table-wrapper.draggable-table tr.mat-header-row,
.table-wrapper.draggable-table tr.mat-footer-row,
.table-wrapper.draggable-table tr.mat-row {
  height: auto;
}
/* .table-wrapper.draggable-table td.mat-cell:first-of-type,
.table-wrapper.draggable-table td.mat-footer-cell:first-of-type,
.table-wrapper.draggable-table th.mat-header-cell:first-of-type {
  padding: 25px;
} */
.table-wrapper.draggable-table .mat-header-cell {
  color: #333;
}
td.dragable-td-child {
  padding: 0 !important;
}
.draggable-table .sub-is-open i:before {
  content: "\F146" !important;
}
.expanded .expandBtn i:before {
  content: "\F146" !important;
}
.expand-icon:before {
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  color: #99a83d;
  margin-right: 7px;
  padding: 0;
}
.table-wrapper.draggable-table td.dragable-row-children {
  padding: 0 0 0 35px !important;
  background: rgba(153, 168, 61, 0.05);
}
/* .table-wrapper.draggable-table tbody tr td,
.table-wrapper.draggable-table thead tr th {
  padding: 25px !important;
} */
.draggable-tr {
  border-top: 1px dashed #efefef;
}
.draggable-table table tbody tr td {
  border-bottom: none;
}
mat-footer-row,
mat-header-row,
mat-row,
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border: none !important;
}
/* .inserted-tr {
  display: none;
} */
td.dragable-row-children {
  padding-left: 35px !important;
}
.cdk-drag-placeholder {
  background: #fff !important;
}
.cdk-drag-preview {
  background: #f4f5f6 !important;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 25px !important;
}
/* end dragable table */

/* mat checkbox modif */
.mat-checkbox-frame {
  border: 1px solid #708295 !important;
  border-radius: 3px !important;
}
span.mat-checkbox-label .star {
  margin-right: 3px !important;
  margin-top: 2px !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #f96332;
}
/* end mat checkbox modif */

/* Select nr of itmes per page */

.view-page-select {
  align-items: center;
  display: flex;
}

.per-page-select {
  padding: 2px 5px;
  width: 50px;
  margin-left: 5px;
  border: 1px solid #e4e3e3;
}
/* end */

.status-label-1 {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-2 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-3 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-4 {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #f2545b;
  text-transform: capitalize;
}

.status-label-5 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-6 {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #00639f;
  text-transform: capitalize;
}

.status-label-7 {
  background: #517820;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-8 {
  background: #ffb545;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-9 {
  background: #d0dae4;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-10 {
  background: #d0dae4;
  color: #333333 !important;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-11 {
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  background: #708295;
  text-transform: capitalize;
}
.status-label-17 {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-50 {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-new,
.status-label-New {
  background: #99a83d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-paid,
.status-label-Paid {
  background: #00639f;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-refunded,
.status-label-Refunded {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}

.status-label-Inactive {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-inactive {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-pending {
  background: #fdcc5d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-rejected {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-declined {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-Active {
  background: #99a83d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-approved {
  background: #99a83d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-active {
  background: #99a83d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-3 {
  background: #89b51a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-4 {
  background: #d0dae4;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-5 {
  background: #e07800;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-6 {
  background: #5f7999;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-7 {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-8 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-9 {
  background: #fdcc5d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-10 {
  background: #e07800;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-17 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-50 {
  background: #f2545b;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-61 {
  background: #b98472;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-200 {
  background: #c55065;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.entity_status-label-201 {
  background: #733733;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-1 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-2 {
  background: #99a83d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-3 {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-4 {
  background: #fdcc5d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-5 {
  background: #960000;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-6 {
  background: #517820;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-7 {
  background: #e07800;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.services-status-label-8 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-1 {
  background: #99a83d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-2 {
  background: #517820;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-3 {
  background: #d3391a;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-4 {
  background: #fdcc5d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-5 {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-6 {
  background: #00639f;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.rfq-status-label-7 {
  background: #e07800;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-uploaded {
  background: #99a83d;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-archived {
  background: #708295;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.status-label-updated {
  background: #00639f;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
div[class^="status-label-"] a,
div[class^="services-status-label-"] a,
div[class^="entity_status-label-"] a,
div[class^="rfq-status-label-"] a {
  color: #fff;
}
.w-10 {
  width: 10%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}

.w-50 {
  width: 50%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-25 {
  width: 25%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-30 {
  width: 30%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-20 {
  width: 20%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-75 {
  width: 75%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-40 {
  width: 40%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-60 {
  width: 60%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}

.w-100 {
  width: 100%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-33 {
  width: 33.3%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-70 {
  width: 70%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-80 {
  width: 80%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.w-90 {
  width: 90%;
  float: left;
  padding-right: 15px;
  padding-left: 15px;
}
.h50 {
  height: 50%;
}

.h75 {
  height: 75%;
}

.h100 {
  height: 100%;
}

.pad10 {
  padding: 10px;
}

.px-10 {
  padding: 0 10px;
}

.pr-10 {
  padding-right: 10px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
  /* display: inline-block;
  width: 100%; */
}
[aria-sort="ascending"] .mat-sort-header-arrow:before {
  content: "\F106";
  font-family: icons;
  color: #f96332;
}
[aria-sort="descending"] .mat-sort-header-arrow:before {
  content: "\F107";
  font-family: icons;
  color: #f96332;
}
.mat-sort-header-arrow:before {
  content: "\F105";
  font-family: icons;
  color: #708295;
}
.mat-sort-header-container:hover .mat-sort-header-arrow::before {
  color: #f96332;
}
.mat-sort-header-arrow {
  opacity: 1 !important;
  transform: translateY(-7px) !important;
}
.mat-sort-header-arrow > div {
  display: none;
}

span.ui-tree-toggler {
  /* position: absolute; */
  -webkit-transform: translate(0, -65%);
  transform: translate(0, -65%);
  margin-top: 1.2em !important;
  /* top: 50%;
  left: 110px;
  width: 20px;
  height: 20px; */
}
.ui-tree .ui-treenode-selectable.ui-treenode-content {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}
.draggable-table .ui-widget-content {
  /* border: none; */
  background: #fff;
  font-family: inherit;
  font-size: inherit;
  position: absolute;
}
.draggable-table .ui-chkbox {
  margin: 0;
}
.draggable-table .ui-treenode,
.draggable-table .ui-tree-container {
  padding: 0 !important;
}
.draggable-table .ui-widget-content {
  border: none;
}
.draggable-table .ui-treenode-children {
  margin: 0 !important;
  padding: 0 0 0 25px !important;
}
.draggable-table .ui-chkbox-box {
  position: relative;
}
.draggable-table .ui-chkbox-box:before {
  content: "\e830";
  font-family: icons;
  display: block;
  position: absolute;
  left: 25px;
  color: #e1e1e1;
  font-size: 16px;
  top: 3px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  display: none;
}

.ui-treenode-content:hover .ui-chkbox-box:before {
  color: #f96332;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.draggable-table .pi-caret-right:before {
  color: #99a83d;
  font-family: icons;
  content: "\f0fe";
  width: 20px;
  height: 20px;
  display: block;
  font-size: 20px;
}
.draggable-table .pi-caret-down:before {
  color: #99a83d;
  font-family: icons;
  content: "\f146";
  width: 20px;
  height: 20px;
  display: block;
  font-size: 20px;
}
.draggable-table .ui-chkbox .ui-chkbox-box {
  border: none;
}
.draggable-table span.ui-chkbox-icon.ui-clickable {
  width: 18px;
  height: 18px;
  border: 1px solid #708295;
  border-radius: 3px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  margin-left: 60px;
  margin-top: -2px;
}
.draggable-table
  .ui-chkbox
  .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border: none;
}
.draggable-table .ui-chkbox-icon.ui-clickable.pi-check {
  background-color: #f96332;
  border: 1px solid #f96332;
}
.draggable-table .pi-check:before {
  content: "\E80D";
  font-family: icons;
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 3px;
  left: 63px;
}
.draggable-table .pi-minus:before {
  font-family: icons;
  content: "\e816";
  font-size: 12px;
  position: absolute;
  top: 3px;
  left: 64px;
}
.draggable-table th:first-child {
  padding-left: 60px;
}
.draggable-table .ui-treenode-label.ui-state-highlight,
.draggable-table .ui-treenode-content-selected {
  background-color: transparent !important;
  color: #333 !important;
}
.pi-minus {
  background-color: #f96332;
  border: 1px solid #f96332 !important;
  color: #fff;
}
.draggable-table .ui-chkbox .ui-chkbox-box {
  background: transparent;
}
.draggable-table .ui-treenode-content-selected .ui-chkbox-box:before {
  color: #333;
}
.draggable-table .ui-treenode-content-selected .ui-chkbox-box:before {
  color: #333;
}
.draggable-table .ui-treenode-content-selected:hover .ui-chkbox-box:before {
  color: #f96332;
}
.draggable-table .ui-state-highlight {
  background-color: #f96332 !important;
}
.draggable-table .ui-tree .ui-treenode-droppoint {
  height: 3px !important;
}
.draggable-table
  .ui-tree.ui-tree-selectable
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: transparent;
  color: #333;
}
.section-type i {
  margin-right: 5px;
}
.help-table {
  position: relative;
}
.help-table i {
  font-size: 24px;
  color: #708295;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}
.editable-input-control {
  border: none;
  border-bottom: 1px solid #efefef;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}
.editable-input-control:focus {
  background: #efefef;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: #708295;
}
.form-err {
  color: #f96332;
  font-size: 12px;
  margin-bottom: 15px;
  display: block;
}
.upload-files input {
  display: none;
}
label.upload-files {
  display: inline-block;
  margin: 0;
}
/* end general */

/* custom checkbox */
input.checkbox-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 18px;
  background-color: #efefef;
  border: 2px solid #efefef;
  border-radius: 50px;
  cursor: pointer;
}

input.checkbox-switch:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -3px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  background: #f96332;
}
input.checkbox-switch.disabled:after {
  background: #c1c4c9;
}
input.checkbox-switch:hover::after {
  background: #db5527;
}
input.checkbox-switch:checked {
  box-shadow: inset 20px 0 0 0 #fcb198;
  border-color: #fcb198;
  background: #fcb198;
}

input.checkbox-switch:checked:after {
  left: 26px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
}
/* end custom checkbox */

/* Ads */
#main-carousel img {
  height: 440px;
  width: 100%;
  object-fit: cover;
}
#main-top-providers img {
  height: 300px;
  width: 1460px;
  object-fit: cover;
  transform-origin: top left;
}
#main-category img {
  height: 240px;
  width: 1140px;
  object-fit: cover;
  transform-origin: top left;
}
#offers_category img {
  height: 200px;
  width: 1140px;
  object-fit: cover;
  transform-origin: top left;
}
#offers_filter img {
  height: 600px;
  width: 250px;
  object-fit: cover;
  transform-origin: top left;
}
#offer_page img {
  width: 320px;
  height: 300px;
  object-fit: cover;
  transform-origin: top left;
}
/* end Ads */

/* auth */
body.auth {
  background: #fff;
}
.auth-right-wrapper {
  background: url("/assets/images/slider-1.jpg");
  background-color: #fff;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 55%;
  right: 0;
  top: 0;
  background-position: center center;
}

.auth-laytout-responsive {
}

.auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-right-title {
  font-size: 36px;
  color: #fff;
  font-weight: 700;
}
.auth-right-desc {
  font-size: 20px;
  color: #fff;
}
.auth-right-line {
  width: 35px;
  height: 4px;
  background: #fff;
  border-radius: 1px;
  margin: 30px 0;
}
.auth-right-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.auth-form-title {
  font-size: 34px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 35px;
}
.auth .btn {
  width: 100%;
}
thead.fixedTableHead {
  position: fixed;
  background: #fff;
  width: auto;
  top: 51px;
  z-index: 99;
  box-shadow: 1px 5px 12px 0 rgba(0, 0, 0, 0.1);
}
/* .auth-left-wrapper {
  width: 45%;
  float: left;
  position: relative;
} */
.auth-form-wrapper {
  padding: 0 75px;
  margin: 50px 0;
}
.auth-form-wrapper form {
  overflow: hidden;
}
.auth-footer {
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 110px 130px;
  font-size: 12px; */
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.show-pass {
  position: absolute;
  right: 10px;
  bottom: 22px;
  cursor: pointer;
  font-size: 20px;
}

.login-footer {
  margin: 0 75px 0px 75px !important;
  /* bottom: 80px; */
}

.auth-form-wrapper .btn {
  margin-top: 10px;
}
.under-form {
  font-size: 12px;
  margin: 20px 0;
}
.auth-left-inner .logo {
  width: 270px;
  margin: 70px auto 0;
}
.auth-left-inner .logo img {
  width: 100%;
}
.auth-lang-selector {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: none;
}
.forgot-pass {
  padding-bottom: 10px;
}
/* end auth */

/* bulk action */
.bulk-action-wrapper {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 15px 30px;
  margin-bottom: 5px;
  position: relative;
  transition: all 0.3s ease-out;
}
.bulk-action-body,
.bulk-action-btn,
.bulk-action-btn div {
  display: inline-block;
}
.bulk-action-close {
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  font-size: 16px;
  color: #708295;
  transform: translate(0, -50%);
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}
.bulk-action-close:hover {
  color: #f96332;
}
.bulk-action-body {
  color: #708295;
  font-weight: 500;
  margin-right: 15px;
}
.bulk-action-btn button {
  margin-right: 10px;
}

.fixed-bulk .bulk-action-wrapper {
  margin-bottom: 0;
}
.fixed-bulk {
  position: fixed;
  width: calc(100% - 340px);
  top: 0;
  z-index: 9;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 0 !important;
}
/* end bulk action */

/* material overlay */
.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.custom-mat-menu-item {
  padding: 10px 15px !important;
  height: auto !important;
  line-height: normal !important;
  color: #708295 !important;
  background: #fff !important;
}
.mat-menu-item i:before {
  position: absolute;
  width: 30px;
  font-size: 24px;
  line-height: 18px;
  margin: 0;
  top: 50%;
  transform: translate(0, -60%);
}
span.nav-item-label {
  padding-left: 35px;
}
.mat-menu-panel {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2) !important;
  min-width: 215px !important;
}
.custom-mat-menu-item.cdk-keyboard-focused:not([disabled]),
.custom-mat-menu-item.cdk-program-focused:not([disabled]),
.custom-mat-menu-item:hover:not([disabled]) {
  background-color: #f0f0f0 !important;
}
.mat-menu-item {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.mat-radio-button .mat-radio-ripple {
  z-index: 1;
  pointer-events: none;
  display: none;
}
/* endmaterial overlay */

/* custom select */
.select-option-with-icon i {
  margin-right: 15px;
  font-size: 18px;
}
.customiz-select.ng-select-opened {
  z-index: 9;
}
.ng-select .ng-select-container {
  min-height: 35px !important;
  transition: all 0.3s ease-out;
}
ng-select.customiz-select {
  margin-bottom: 15px;
}
ng-select.customiz-select.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}
ng-select.customiz-select.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #f96332 !important;
}
ng-select.customiz-select.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-color: #f96332 transparent transparent !important;
}
ng-select.customiz-select.ng-select.ng-select-opened
  > .ng-select-container:hover
  .ng-arrow {
  border-color: transparent transparent #f96332 !important;
}
ng-select.customiz-select.ng-select .ng-clear-wrapper .ng-clear {
  line-height: 45px !important;
}
ng-select.customiz-select.ng-select .ng-clear-wrapper {
  color: #708295 !important;
}
ng-select.disable-clear-all .ng-clear-wrapper {
  display: none;
}
ng-select.customiz-select.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #708295 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
ng-select.customiz-select.ng-select.ng-select-opened
  > .ng-select-container
  .ng-arrow {
  top: -2px;
  border-color: transparent transparent #708295;
  border-width: 0 5px 5px;
}
ng-select.customiz-select .ng-select-container {
  height: 45px !important;
  border: none;
  background: #f0f0f0;
  border-radius: 4px !important;
  line-height: 45px;
}
ng-select.customiz-select.white-select .ng-select-container {
  background: #fff;
}
ng-select.customiz-select .ng-select-container:hover {
  box-shadow: none;
}
ng-select.customiz-select .ng-select-container .ng-value-container,
ng-select.customiz-select.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  padding-left: 25px;
  padding-top: 0;
}
ng-select .ng-select-container .ng-value-container .ng-input {
  position: absolute;
  left: 0;
  width: 100%;
}
ng-select .ng-arrow-wrapper,
.ng-select .ng-clear-wrapper {
  z-index: 2;
}
ng-select.customiz-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
ng-select.customiz-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 50px 0 25px;
  height: 45px;
  line-height: 45px;
}
ng-select.customiz-select
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  font-size: 13px;
  height: 45px;
  line-height: 45px;
  padding: 0;
}
.customiz-selectng-select span,
ng-select.customiz-select div {
  font-size: 13px !important;
  color: #333 !important;
}
ng-select.customiz-select input {
  font-size: 13px !important;
  color: #333 !important;
}
ng-select.customiz-select.ng-select-opened > .ng-select-container {
  background: #f0f0f0;
  border: none;
}
ng-select.customiz-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input {
  top: 0;
}
ng-select.customiz-select.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  border-color: none !important;
  box-shadow: none !important;
}
ng-select.customiz-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: #f0f0f0 !important;
  color: #333 !important;
  padding: 10px 15px !important;
}
ng-select.customiz-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  padding: 10px 15px !important;
}
ng-select.customiz-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  background-color: #f0f0f0;
  color: #333 !important;
}
ng-select.customiz-select .ng-dropdown-panel .scroll-host {
  border: none;
  background: #efefef;
}
ng-select.customiz-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label {
  font-weight: 500;
}
ng-select.customiz-select .ng-dropdown-panel.ng-select-bottom {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #708295;
  border-top-color: rgba(155, 155, 155, 0.5);
}
ng-select.chips-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  position: relative !important;
  top: 0 !important;
  padding: 0 !important;
  display: block;
}
ng-select.chips-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  display: none;
  z-index: 999;
}
/* ng-select.chips-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    position: absolute;
    top: 25px;
} */
ng-select.chips-select.ng-select-multiple .ng-select-container {
  overflow: visible;
}
.customa-chips {
  background: #f96332;
  color: #fff;
  font-weight: 500;
  padding: 10px 42px 10px 10px;
  border-radius: 4px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}
.remove-chips {
  display: inline-block;
  font-size: 22px;
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.chips-select.ng-select-opened .hide-placeholder {
  display: none !important;
}
ng-select.customiz-select .ng-dropdown-panel {
  border: 1px solid #efefef !important;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1) !important;
  background: #fff !important;
  margin-top: 0px !important;
  min-width: 135px;
}
/* .elem-page-wrapper ng-select.customiz-select.white-select .ng-select-container {
  min-width: 80px;
} */
.elem-page-wrapper
  ng-select.customiz-select
  .ng-select-container
  .ng-value-container {
  padding-left: 15px;
}
.elem-page-wrapper
  ng-select.customiz-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
.elem-page-wrapper
  ng-select.customiz-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 40px 0 15px;
}
ng-select.customiz-select.white-select.ng-select-opened > .ng-select-container {
  background: #fff;
}
.customiz-select.white-select {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.min-select-width {
  min-width: 180px;
}
.elem-page-wrapper ng-select.customiz-select {
  margin-bottom: 0;
}
.elem-page-wrapper ng-select.customiz-select .ng-select-container {
  font-weight: 500;
}
.location-select .ng-select-container .ng-value-container,
.location-select .ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 30px !important;
  padding-top: 0 !important;
}
ng-select.location-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
ng-select.location-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 15px 0 30px !important;
}
ng-select.location-select {
  width: 190px;
}
ng-select.lang-select {
  text-transform: uppercase;
  margin-bottom: 0;
}
ng-select.lang-select .ng-select-container .ng-value-container,
ng-select.lang-select.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  padding-left: 15px;
  padding-top: 0;
}
ng-select.lang-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
ng-select.lang-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  height: 35px;
  line-height: 35px;
  padding: 0 15px 0 15px;
}
ng-select.customiz-select-white-bg .ng-select-container {
  background: #fff;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
ng-select.customiz-select-white-bg.ng-select-opened > .ng-select-container {
  background: #fff;
  border: none;
}
ng-select.no-clearable-select.ng-select-single
  .ng-select-container
  .ng-value-container
  .ng-input,
ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: 0 20px 0 15px !important;
  height: 45px;
  line-height: 45px;
}
ng-select.no-clearable-select .ng-select-container .ng-value-container,
ng-select.no-clearable-select.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 0;
}
.tab-card-list-wrapper ng-select.customiz-select-white-bg .ng-select-container {
  background: #f0f0f0;
}
.advanced-search-wrapper .customiz-select {
  margin-bottom: 0;
  min-width: 222px;
}
/* end custom select */
/* placeholder */
::-webkit-input-placeholder {
  color: #333;
}
::-moz-placeholder {
  color: #333;
}
:-ms-input-placeholder {
  color: #333;
}
:-moz-placeholder {
  color: #333;
}

.placeholder-text {
  font-style: italic;
  font-weight: 500 !important;
  color: #708295;
}
/* end placeholder */
/* custom tree list */
.lvl-1,
.lvl-2,
.lvl-3 {
  padding-left: 30px;
}
.lvl-0 > .tree-list-name {
  font-size: 16px;
  font-weight: 500;
}
.tree-list-row-unique > *,
.editable-input-wrapper > * {
  display: inline-block;
}
.tree-list-row-unique .tree-list-checkbox label {
  margin: 0;
  padding-right: 10px;
}
.tree-list-row-unique {
  padding: 20px 0;
}
.tree-list-row.lvl-0 {
  border-bottom: 2px solid #f0f0f0;
}
.tree-list-wrapper {
  margin-bottom: 40px;
}

.tree-row-details {
  float: left;
}
.tree-row-wrapper {
  padding-left: 20px;
  display: inline-block;
  width: 100%;
  position: relative;
  font-size: 0;
}

.sub-is-open ~ .toggle-tree {
  display: block;
}
.toggle-tree {
  display: none;
}
.toggle-row-action.sub-is-open i:before {
  content: "\e819" !important;
}
/* #tree-row-extrawrapper > .tree-row-wrapper:first-child {
  border-top: none;
} */
.tree-row-wrapper .mat-checkbox-layout {
  margin-bottom: 0;
}
.toggle-row-action {
  position: absolute;
  left: -5px;
  top: 12px;
  cursor: pointer;
  font-size: 20px;
}
.toggle-row-action:hover {
  color: #f96332;
}
.tree-row {
  border-bottom: 1px solid #f0f0f0;
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  /* margin-left: 15px; */
}
.treeRow-lvl1 .tree-check {
  display: none;
}
.tree-label {
  margin-left: 15px;
}
.tree-label span {
  margin-right: 5px;
  font-weight: 500;
}
.tree-row-details .edit-input {
  width: 95px;
  height: 40px;
  line-height: 24px;
  border: none;
  text-align: center;
  color: #f96332;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 7px;
}
.commission-column-head {
  padding-top: 10px;
  margin-right: 20px;
}
.tree-row-details .edit-input:focus {
  background: #f0f0f0;
}
.tree-row-details .toggle-calendar-icon {
  height: auto;
  line-height: 56px;
  font-size: 16px;
  width: auto;
  margin-left: 5px;
}
.tree-row-date {
  color: #708295;
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;
}
.tree-check .mat-checkbox-inner-container {
  width: 20px;
  height: 20px;
}
.tree-row .tree-label {
  width: calc(100% - 35px);
}
/* end custom tree list */
/* form */
.form-control {
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 10px 25px;
  width: 100%;
  font-size: 13px;
  line-height: 45px;
  margin-bottom: 15px;
  display: block;
  height: 45px;
  color: #333;
}
.form-control:focus {
  color: #333;
  background: #f0f0f0;
  border: none;
  outline: 0;
  box-shadow: none;
}
.form-group-radio-btn {
  margin-bottom: 5px;
  display: inline-block;
}
.form-group-radio-btn label {
  padding: 0 !important;
  margin: 0 !important;
}
.form-group-radio-btn .mat-radio-label-content {
  font-weight: 400;
}
.form-group-radio-btn .mat-radio-button {
  margin-right: 15px;
}
.input-helper {
  /* float: right; */
  cursor: pointer;
  margin-left: 5px;
  position: relative;
}
.input-helper i:before,
.characteristic-helper i:before {
  padding: 0;
  margin: 0;
  color: #708295;
  font-size: 16px;
  line-height: 16px;
}
.input-helper:hover i:before,
.characteristic-helper:hover i:before {
  color: #f96332;
}
.characteristic-helper {
  cursor: pointer;
  float: right;
  position: relative;
  top: 11px;
}
.characteristic-left {
  float: left;
  width: 70%;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.characteristic-right {
  float: right;
  height: 65px;
  width: 30%;
}
.characteristic-right .checkbox-switch,
.characteristic-right .form-control {
  float: right;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.characteristic-right .form-control {
  height: 35px;
  line-height: 35px;
}
.characteristic-row {
  height: 65px;
}
.characteristic-row .white-box-body {
  padding: 0;
}
.form-group-row label {
  margin-bottom: 0;
  font-weight: 500;
  position: relative;
}
.form-group-row .form-control,
.form-group-row ng-select.customiz-select,
.form-group-row label {
  margin-bottom: 0;
}
.helper-description {
  position: absolute;
  right: 0;
  background: #fff;
  top: 20px;
  z-index: 99;
  padding: 15px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  min-width: 140px;
  display: none;
}

.helper-rfq {
  right: auto;
  top: 35px;
  min-width: 230px;
}
/*
.characteristic-left .helper-description {
  top: 40px;
} */
.characteristic-wrapper {
  display: block;
  padding-bottom: 25px;
}
.visible-helper .helper-description {
  display: block;
}
.form-group {
  margin-bottom: 0;
}
.form-group label {
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  position: relative;
}
.auth .form-group label {
  display: table-cell;
  height: 40px;
  vertical-align: middle;
}
/* .auth .form-group .form-control {
  margin-bottom: 5px;
  margin-top: 0;
} */
.auth ng-select.customiz-select {
  margin-bottom: 15px;
  margin-top: 0;
}
.auth .form-group .textarea-control {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.textarea-control {
  resize: vertical;
  background: #f0f0f0;
  width: 100%;
  border: none;
  border-radius: 4px;
  min-height: 75px;
  padding: 10px 25px;
  font-size: 13px;
  margin-bottom: 15px;
}

.textarea-control-provider {
  resize: vertical;
  background: #f0f0f0;
  width: 100%;
  border: none;
  border-radius: 4px;
  min-height: 170px;
  padding: 10px 25px;
  font-size: 13px;
  margin-bottom: 15px;
  line-height: inherit;
}

.textarea-control-chat {
  resize: none;
  background: #f0f0f0;
  width: 100%;
  border: none;
  border-radius: 4px;
  height: 40px;
  padding: 10px 25px;
  font-size: 13px;
  line-height: 20px;
}
.msg-input-wrapper {
  padding: 0 20px 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
}
.msg-input-wrapper textarea {
  float: left;
  width: calc(100% - 78px) !important;
}
.msg-input-wrapper .btn {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px #f0f0f0 inset;
}
/* end form */

/* alert-notific */
.notification-wrapper {
  font-size: 14px;
  font-weight: 500;
  padding: 8px 35px;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -ms-transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  z-index: 9999;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  /* -webkit-box-shadow: 1px 1px 12px 0 rgba(0,0,0,0.2);
    box-shadow: 1px 1px 12px 0 rgba(0,0,0,0.2); */
}
.notificatio-open {
  -ms-transform: translate(-50.1%, 0);
  -webkit-transform: translate(-50.1%, 0);
  transform: translate(-50.1%, 0);
}
.succes-notification {
  background: #99a83d;
  color: #fff;
}

.alert-wrapper {
  z-index: 1000;
  padding: 10px 30px;
  font-weight: 500;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  top: 0;
  text-align: left;
  background: #fcfcff;
  -ms-transition: all 0.25s ease-in-out;
  -ms-transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 9999;
}
.notification-error-wrapper {
  z-index: 1000;
  padding: 10px 30px;
  font-weight: 500;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  top: 0;
  text-align: left;
  background: #fcfcff;
  -ms-transition: all 0.25s ease-in-out;
  -ms-transform: translate(-50%, -100%);
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 9999;
}
.alert-wrapper.open-errors,
.notification-wrapper.notificatio-open,
.notification-error-wrapper.open-errors {
  -webkit-box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2);
}
.open-errors {
  -ms-transform: translate(-50.1%, 0);
  -webkit-transform: translate(-50.1%, 0);
  transform: translate(-50.1%, 0);
}
.error-description {
  padding: 3px 5px;
  position: relative;
}
.notification-error-wrapper .error-description:before {
  content: "\e857";
  color: #f96332;
  font-family: icons;
  position: absolute;
  left: -15px;
  top: 2px;
  font-size: 25px;
}
.notification-error-wrapper .error-description {
  padding: 3px 5px 3px 25px;
  position: relative;
}
.error-description:before {
  content: "\E84D";
  color: #f96332;
  font-family: icons;
  position: absolute;
  left: -15px;
  top: 2px;
}
.alert-wrapper button,
.notification-error-wrapper button {
  margin-top: 5px;
}
/* end alert-notific */

/* pagination */
.paginator {
  text-align: center;
  padding: 0 0 20px;
}
.paginator .ngx-pagination a,
.ngx-pagination button {
  color: #e1e1e1;
  font-weight: 500;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.paginator .ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: transparent;
}
.paginator .ngx-pagination a:hover,
.ngx-pagination button:hover {
  color: #f96332;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.paginator .ngx-pagination .current {
  color: #f96332;
  background: transparent;
  font-weight: 500;
}

.paginator .ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
  content: "\E80B" !important;
  display: inline-block;
  font-family: "icons";
  font-size: 18px;
  position: relative;
  top: -2px;
}
.paginator .ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-next.disabled::after {
  content: "\E80C" !important;
  display: inline-block;
  font-family: "icons";
  font-size: 18px;
  position: relative;
  top: -2px;
}
.paginator .ngx-pagination .disabled {
  color: #e1e1e1;
  font-weight: 500;
}
.paginator ul.ngx-pagination {
  font-size: 16px;
  margin-bottom: 0 !important;
  padding: 0;
}

.rfq-paginator ul.ngx-pagination {
  font-size: 20px !important;
}
/* end pagination */

/* left navigation */
.left-navigation-wrapper {
  width: 250px;
  background: #fff;
  position: fixed;
  height: 100%;
  left: 0;
  box-shadow: 1px 2px 9px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.left-navigation-wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.active-nav,
.left-navigation-wrapper ul li:hover {
  background: #f6f6f6;
  position: relative;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.active-nav i,
.left-navigation-wrapper ul li:hover i {
  color: #f96332;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.active-nav:before,
.left-navigation-wrapper ul li:hover::before {
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f96332;
  content: "";
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.left-navigation-wrapper ul li {
  padding: 15px 0;
  cursor: pointer;
  padding-left: 40px;
  font-weight: 500;
}
.left-navigation-wrapper ul li a {
  position: relative;
  padding-left: 35px;
  display: block;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.left-navigation-wrapper ul li a i,
.left-navigation-wrapper ul li a .icon-wrapper {
  padding: 0 15px 0 0;
  font-size: 18px;
  position: absolute;
  top: -2px;
  left: -5px;
  transition: all 0.2s ease-in-out;
}
.btn-wrapper {
  text-align: center;
}
.btn-wrapper a {
  display: inline-block;
}
.left-navigation-wrapper .btn-wrapper {
  margin-bottom: 35px;
}
.has-sub-nav a:after {
  content: "\F105";
  font-family: icons;
  position: absolute;
  right: 15px;
  top: -1px;
  font-weight: 700;
  font-size: 16px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.left-navigation-elem-wrapper {
  top: 190px;
  bottom: 0;
  width: 250px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}
.left-navigation-wrapper .logo {
  width: 152px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 16px;
  padding-bottom: 16px;
}
.left-navigation-wrapper .logo img {
  width: 100%;
}
/* .has-sub-nav.active-nav a:after {
    color: #F96332;
    content: '\F107';
} */
.sub-is-open a:after {
  color: #f96332;
  content: "\F107";
}
.has-sub-nav:hover a::after {
  color: #f96332;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.sub-navigation {
  background: #f6f6f6;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1) -0.1s;

  overflow: hidden;
}
.sub-navigation .active-sub-nav {
  color: #f96332 !important;
}
.sub-is-open + .open-sub-navigation {
  max-height: 1500px;
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
  transition-delay: 0s;
}

.sub-navigation ul li a {
  color: rgba(59, 57, 53, 0.3);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.sub-navigation ul li .active-sub-nav,
.sub-navigation ul li:hover a {
  color: #333;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}

/* end left navigation */

/* loader */
.loader-wrapper {
  position: absolute;
  z-index: 1002;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader-wrapper img {
  width: 70px;
}
.extra-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10005;
  top: 0;
  left: 0;
}
/* end loader */

/* custom radio */
.container-radio {
  display: inline-block;
  position: relative;
  padding: 0 30px 0 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 40px;
  line-height: 40px;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 9px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #8f96a6;
}

/* When the radio button is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-radio .checkmark:after {
  top: 1px;
  left: 1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #4a90e2;
}
/* end custom radio */

/* custom checkbox */
/* Customize the label (the container) */
.container-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
}

/* Create a custom checkbox */
.checkmark-checkbox {
  position: absolute;
  top: -15px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #708295;
  border-radius: 3px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark-checkbox {
  background-color: #f96332;
  border: 1px solid #f96332;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.container-checkbox input:checked ~ .checkmark-checkbox:after {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  content: "\E80D";
  color: #fff;
  position: absolute;
  top: 3px;
  font-size: 11px;
  left: 1px;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark-checkbox when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*  end custom checkbox */

/* qty range */
.cart-item-details-range-wrapper {
  min-height: 35px;
}

.cart-item-details-range button {
  border: none;
  background: transparent;
  box-shadow: none;
  color: #cacaca;
  cursor: pointer;
}
.cart-item-details-range button:hover,
.cart-item-details-delete:hover {
  color: #f96332;
}
.cart-item-details-range button i {
  font-size: 16px;
}
.cart-item-details-range {
  float: left;
}
.cart-item-details-range button:first-child {
  float: left;
  line-height: 35px;
  margin: 0;
  height: 35px;
  padding: 0 2px;
}

.cart-item-details-range button:last-child {
  float: left;
  line-height: 35px;
  margin: 0;
  height: 35px;
  padding: 0 2px;
}
.cart-item-details-range-wrapper {
  /* display: inline-block;
  width: 100%; */
  min-height: 35px;
}

.cart-range-qty {
  background: #f0f0f0;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 35px !important;
  float: left;
  font-size: 14px;
  border-radius: 4px;
}
.input-cart-range-qty {
  background: #f0f0f0;
  text-align: center;
  width: 45px;
  height: 30px;
  line-height: 25px !important;
  float: left;
  font-size: 14px;
  border-radius: 4px;
  border: none;
}
.input-cart-range-qty::-webkit-outer-spin-button,
.input-cart-range-qty::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-cart-range-qty[type="number"] {
  -moz-appearance: textfield;
}
/* end qty range */

/* modal */

.form-control-modal {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e5eaee;
  width: 100%;
  font-size: 15px;
  height: 40px;
  padding: 10px 25px;
}
.custom-modal-title-wrapper {
  border-bottom: 1px solid #e5eaee;
  padding-bottom: 30px;
  display: inline-block;
  width: 100%;
}
.custom-modal-title {
  color: #00579f;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}
.modal-footer {
  /* display: inline-block;
    width: 100%; */
  float: right;
  padding: 0;
  border: none;
}
.modal-footer-l {
  float: left;
}
.modal-footer-r button {
  width: 130px;
  margin-left: 20px;
}
.modal-footer-r {
  float: right;
}
.modal-footer button {
  width: 130px;
  margin-left: 20px !important;
}
.modal-wrapper {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}
body.dialog-open {
  overflow: hidden;
}
body.dialog-open ngx-smart-modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  /* pointer-events: none; */
}

.nsm-dialog-btn-close {
  display: none;
  border: 0;
  background: none;
  color: #2d2d2d;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 0.5s;
  background-color: transparent;
  z-index: 999;
}
.overlay.nsm-overlay-open {
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay.nsm-overlay-close,
.overlay.transparent {
  background-color: transparent;
}

.nsm-dialog {
  position: absolute;
}
.nsm-dialog:not(.nsm-dialog-open),
.nsm-dialog:not(.nsm-dialog-close) {
  opacity: 0;
  min-height: 0;
  width: 0;
  max-width: 0;
  visibility: hidden;
  overflow: hidden;
}
.nsm-dialog.nsm-dialog-open {
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 90%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 4px;
  right: 0;
  left: 0;
  top: 10%;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  pointer-events: auto;
  overflow: visible;
}
#editRFQAndBid .nsm-dialog.nsm-dialog-open,
#addRFQAndBid .nsm-dialog.nsm-dialog-open {
  max-height: 80vh;
  overflow: auto;
}

/* #addRFQAndBid .nsm-dialog.nsm-dialog-open,
#editRFQAndBid .nsm-dialog.nsm-dialog-open {
  max-width: 90rem;
} */

#cardModal .nsm-dialog.nsm-dialog-open,
#makePayment .nsm-dialog.nsm-dialog-open {
  max-width: 800px;
}

.mat-dialog-container {
  background: #fff;
  color: #333;
  border-radius: 4px !important;
  padding: 0 !important;
  max-width: 800px;
  width: 800px !important;
  margin: 50px 0;
  min-height: 200px !important;
  max-height: 90vh !important;
  overflow: auto !important;
}
body.largeModal .cdk-overlay-pane {
  max-width: 90vw !important;
}
body.largeModal .mat-dialog-container {
  width: 1200px !important;
  max-width: 1200px !important;
}
.mat-dialog-container .custom-modal-wrapper {
  padding: 30px;
  display: inline-block;
  width: 100%;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  overflow-y: auto;
  pointer-events: auto;
  overflow-x: hidden;
}
.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box:last-child
  .mat-menu-panel {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.cdk-overlay-container
  .cdk-overlay-connected-position-bounding-box:first-child
  .mat-menu-panel {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.custom-modal-wrapper .modal-title-custom {
  font-size: 26px;
  font-weight: 700;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.custom-modal-wrapper .modal-body-custom {
  margin: 30px 0;
  display: inline-block;
  width: 100%;
}
select.modal-select {
  border-radius: 4px;
  padding: 8px;
  border: none;
  background: #f0f0f0;
  color: #708295;
}
.custom-modal-wrapper .modal-footer-custom button {
  width: 130px;
  margin-left: 20px !important;
}
.custom-modal-wrapper .modal-footer-custom {
  float: right;
}
.custom-modal-wrapper .row:last-child .form-control {
  margin-bottom: 0;
}
.select-input-row .customiz-select {
  width: 135px;
  float: left;
  margin-right: 10px;
}
.select-input-row input {
  width: calc(100% - 145px);
}
.modal-txt {
  margin-bottom: 15px;
}
.custom-modal-wrapper .small-box,
.custom-modal-wrapper .white-box-wrapper {
  background: transparent;
  box-shadow: none;
}
.modal-body-custom .details-body-wrapper {
  padding: 0;
}
.modal-body-custom .small-box {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2);
}
.modal-body-custom .white-box-wrapper {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2);
}
.form-group-title {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0 15px 0;
}
.modal-other-info-list {
  margin-bottom: 15px;
  white-space: pre-line;
}
.modal-other-info-list p {
  margin-bottom: 5px;
}
/* end modal */
/* custom upload box */
.custom-upload-box file-upload {
  overflow: hidden;
  display: block;
  background: #f0f0f0;
  padding: 15px;
  min-height: 140px;
  border: 1px dashed #9b9b9b;
  position: relative;
  border-radius: 4px;
  outline: none;
  outline-offset: 0;
}
.custom-upload-box file-upload .upload-input {
  color: #9b9b9b;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: 0;
}
.custom-upload-box .upload-input span {
  display: block;
  text-align: center;
}

.custom-upload-box .upload-input span i {
  font-size: 18px;
}
.custom-upload-box file-upload .upload-input:hover {
  color: #9b9b9b !important;
}
.custom-upload-box file-upload .upload-input:hover .orange {
  text-decoration: underline;
}
.custom-upload-box file-upload.dragover .upload-input {
  color: #f96332 !important;
}
.custom-upload-box file-upload.dragover {
  border: 1px dashed #f96332 !important;
}
.custom-upload-box file-upload.list-visible .upload-input {
  display: none;
}
.custom-upload-box file-upload .file-info {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.custom-upload-box file-upload .file-info span {
  display: block;
  text-align: center;
  margin: 5px 0;
}
.custom-upload-box file-upload .file-info .remove-file {
  cursor: pointer;
  color: #f96332;
}
/* end custom upload box */
.card-box img {
  width: 100%;
}
.card-box {
  margin: 0 0 15px 0;
  text-align: center;
}
.expiration-date-box {
  position: relative;
}
.expiration-date-box:first-child:after {
  content: "/";
  position: absolute;
  top: 15px;
  right: -23px;
}

@media (min-width: 768px) {
  .nsm-dialog.nsm-dialog-open {
    margin-bottom: 40px;
  }
}
.nsm-dialog.nsm-dialog-close {
  opacity: 0;
}

/* *************************
  * Animations
  * *************************/
.nsm-dialog-animation-fade {
  transition: opacity 100ms;
}

.nsm-dialog-animation-ltr {
  left: -50%;
  transition: left 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-ltr.nsm-dialog-open {
  left: 0;
}
.nsm-dialog-animation-ltr.nsm-dialog-close {
  left: -50%;
}

.nsm-dialog-animation-rtl {
  right: -50%;
  transition: right 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-rtl.nsm-dialog-open {
  right: 0;
}
.nsm-dialog-animation-rtl.nsm-dialog-close {
  right: -50%;
}

.nsm-dialog-animation-ttb {
  top: -50%;
  transition: top 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-ttb.nsm-dialog-open {
  top: 5%;
}
.nsm-dialog-animation-ttb.nsm-dialog-close {
  top: -50%;
}

.nsm-dialog-animation-btt {
  top: 100%;
  transition: top 100ms ease-in-out, opacity 100ms;
}
.nsm-dialog-animation-btt.nsm-dialog-open {
  top: 5%;
}
.nsm-dialog-animation-btt.nsm-dialog-close {
  top: 100%;
}

.nsm-dialog[class*="nsm-dialog-animation-"] {
  transition: opacity 0.3s, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s;
  transition: transform 0.3s ease-in-out, opacity 0.3s,
    -webkit-transform 0.3s ease-in-out;
}

.modal-title {
  font-size: 26px;
  font-weight: 700;
  float: left;
  text-transform: capitalize;
}
.modal-title-wrapper {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.modal-step-wrapper {
  float: right;
  margin-top: 20px;
}
.modal-step-wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.modal-step-wrapper ul li {
  display: inline-block;
}
.modal-step-wrapper ul li.active-step {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  margin-left: 25px;
  position: relative;
  color: #333;
}
.modal-step-wrapper ul li {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  margin-left: 25px;
  position: relative;
  color: #e1e1e1;
}
.modal-step-wrapper ul li.active-step:before {
  content: "";
  background: #f96332;
  height: 5px;
  position: absolute;
  width: 100%;
  bottom: -17px;
}
.modal-body-desc {
  color: #9b9b9b;
  font-size: 14px;
  margin: 15px 0;
}
.custom-modal-body {
  display: inline-block;
  width: 100%;
  margin: 30px 0;
}
.modal-body-enum p {
  margin-bottom: 10px;
}
.modal-body-enum p span {
  font-weight: 600;
  margin-right: 5px;
  display: block;
}
/* end modal */

/* time picker */
.timepicker .btn {
  padding: 1px 20px;
  color: #f96332;
}

.timepicker .form-control {
  margin-bottom: 0px;
  padding: 5px 10px;
}

.timepicker .form-control {
  margin-bottom: 0px;
}
.ngb-tp-input-container.ngb-tp-minute {
  margin-bottom: 0px;
}

.timepicker .btn-outline-primary {
  border-color: #f96332;
  border: 1px solid;
}

.timepicker .btn-outline-primary:hover {
  color: #fff !important;
  background-color: #f96332;
  border-color: #f96332;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #f96332;
  border-color: #f96332;
}

/* end time picker */

/* calendar */
.toggle-calendar-icon {
  height: 45px;
  line-height: 45px;
  background: #fff;
  width: 45px;
  text-align: center;
  font-size: 20px;
  color: #9b9b9b;
  cursor: pointer;
  border-radius: 4px;
  float: left;
}
.toggle-calendar-icon:hover {
  color: #f96332;
}
.editable-input-calendar {
  float: left;
}
.dashboard-body-action .calendar-input-control {
  height: 45px;
  line-height: 45px;
  background: #fff;
  border-radius: 4px;
  padding: 10px 5px;
  border: none;
  font-size: 13px;
  text-align: center;
  color: #333;
}
/* .editable-input-calendar:before {
  font-family: 'icons';
  content: '\e81d';
  color: #9b9b9b;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 3px;
} */
.editable-input-calendar {
  position: relative;
}
.toggle-is-open + .range-calendar-wrapper {
  display: block;
}
.calendar-input-target ~ .range-calendar-wrapper {
  display: block;
}
.range-calendar-wrapper {
  position: absolute;
  top: 48px;
  z-index: 99;
  background: #fff;
  left: 0;
  display: none;
}
.dashboard-statistics-wrapper .range-calendar-wrapper {
  right: 0;
  left: unset;
}
.range-calendar-wrapper ngb-datepicker {
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #efefef;
}
.range-calendar-wrapper .ngb-dp-header {
  background: #fff !important;
  border-radius: 0;
  padding: 5px;
}
.range-calendar-wrapper .ngb-dp-arrow span {
  color: #f96332 !important;
}
.range-calendar-wrapper .custom-select {
  color: #9b9b9b;
  font-weight: 500;
  border: 1px solid #efefef;
  margin: 0 5px;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  width: auto;
}
.range-calendar-wrapper .custom-select option {
  background: #fff;
}
.range-calendar-wrapper .ngb-dp-weekday {
  background: #fff;
  color: #f96332;
  font-weight: 500 !important;
  font-size: 12px;
}
.range-calendar-wrapper .ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  color: #9b9b9b;
}
.range-calendar-wrapper .ngb-dp-weekdays {
  border-bottom: 1px solid #efefef !important;
  background: #fff !important;
}
.range-calendar-wrapper .ngb-dp-navigation-select {
  flex: none;
}
.calendar-input-control {
  border: none;
  /* border-bottom: 1px solid #efefef; */
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
  background: #f0f0f0;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #333;
}
.custom-modal-body .calendar-input-wrapper {
  margin-bottom: 15px;
}
.range-calendar-wrapper .ngb-dp-month-name {
  background: #fff !important;
  color: #333;
}
.show-datepicker {
  display: block !important;
}
.calendar-range-picker {
  position: absolute;
  z-index: 999;
  display: none;
  top: 75px;
  right: 0;
  -webkit-box-shadow: 0 8px 16px 0 rgba(74, 144, 226, 0.15);
  box-shadow: 0 8px 16px 0 rgba(74, 144, 226, 0.15);
}
.date-range-picker-wrapper {
  position: relative;
  display: inline-block;
}
.date-range-open {
  border: 1px solid #d7dee7;
  background: #fff;
  line-height: 40px;
  display: inline-block;
  padding: 0 35px 0 20px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  color: #8f96a6;
}
.date-range-open:after {
  content: "\e800";
  margin-left: 10px;
  display: inline-block;
  font-family: "icons";
  font-size: 13px;
  position: absolute;
  top: 0;
  right: 10px;
  transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.date-range-selected-wrapper {
  background: #fff;
  text-align: center;
  color: #8f96a6;
  border: 1px solid #d7dee7;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 80px;
  line-height: 80px;
}
.date-range-selected-box {
  border: 1px solid #d7dee7;
  background: #fff;
  line-height: 40px;
  display: inline-block;
  padding: 0 15px;
  border-radius: 4px;
  margin: 0 10px;
}
.date-range-action {
  background: #fff;
  line-height: 40px;
  display: inline-block;
  padding: 0;
  margin: 0 10px;
}
.date-range-action-btn {
  display: inline-block;
  border: 1px solid #d7dee7;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
  cursor: pointer;
}
.ngb-datepicker-custom {
  background: #fff;
  padding: 20px;
  font-size: 14px;
  border: 1px solid #d7dee7 !important;
  border-top: none !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  display: block !important;
}
.ngb-dp-weekday,
.ngb-dp-week-number {
  text-align: center;
  font-style: normal !important;
  font-weight: 500 !important;
}
.ngb-dp-month-name {
  font-size: 15px !important;
  text-align: center !important;
  font-weight: 500 !important;
}
.ngb-dp-weekdays {
  border-bottom: 1px solid #d7dee7 !important;
  border-radius: 0 !important;
  margin-top: 15px !important;
}
.custom-day {
  text-align: center;
  display: inline-block;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-day.focused,
.custom-day.selected {
  background-color: #f96332;
  color: #fff;
  border-radius: 4px;
}

.custom-day.focused-single {
  background-color: #f96332 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.custom-day.faded-opacity {
  background-color: #efefef;
  color: inherit;
  border-radius: 0;
  opacity: 0.6;
}

.custom-day.range,
.custom-day:hover {
  background-color: #f96332;
  color: #fff;
  border-radius: 4px;
}
.custom-day.faded {
  background-color: #efefef;
  color: inherit;
  border-radius: 0;
}
.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}
.mat-button-focus-overlay {
  background: rgba(188, 188, 236, 0.2) !important;
}
.mat-button-ripple.mat-ripple {
  display: none;
}
.mat-calendar-body-selected {
  background-color: #4a90e2;
  color: #fff !important;
}
.mat-datepicker-toggle-active {
  color: #4a90e2;
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none !important;
}
.modal-calendar-wrapper .toggle-calendar-icon {
  margin-top: 30px;
}
.modal-calendar-wrapper {
  position: absolute;
}
.calendar-input-wrapper {
  position: relative;
}
.calendar-input-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  color: #9b9b9b;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.modal-calendar-wrapper .range-calendar-wrapper {
  top: 80px;
}
.fix-position-calendar-modal {
  height: 75px;
}
.m-t-calendar-icon {
  margin-top: 30px;
}
/* end calendar */

/* chat */
.chat-page-left {
  float: left;
  width: 65%;
}
.chat-page-right {
  float: left;
  width: calc(35% - 130px);
  position: absolute;
  overflow: auto;
  height: 528px;
  right: 45px;
}
.chat-page-wrapper {
  display: inline-block;
  width: 100%;
}
.selected-msg {
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.selected-msg td {
  background: #f4f5f6;
}
.msg-conv-wrapper {
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.05);
}
.msg-header-wrapper {
  background: #fff;
  width: 100%;
  position: absolute;
  right: 0;
  height: 72px;
  line-height: 72px;
  border-bottom: 1px solid #efefef;
  padding: 0 20px;
}
.msg-header {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  width: calc(100% - 28px);
}
.msg-header-action {
  float: left;
  width: 28px;
}
.msg-header-action i:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #9b9b9b;
  font-size: 20px;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.toggle-is-open.msg-header-action i:before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.msg-header-action i:hover::before {
  color: #f96332;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.chat-body {
  padding: 85px 20px 0 20px;
  font-size: 13px;
  height: 528px;
  overflow-y: auto;
  border-bottom: 70px solid transparent;
}
/* .message-wraper span {
  display: block;
} */
.msg-user-name {
  color: #f96332;
  margin-bottom: 5px;
  display: block;
}
.msg-user-text {
  background: #f0f0f0;
  display: inline-block !important;
  padding: 5px 10px;
  border-radius: 4px;
  color: #9b9b9b;
  word-break: break-word;
}
.msg-date {
  color: #9b9b9b;
  font-size: 10px;
  margin: 5px 0;
  display: block;
}
.message-wraper {
  margin-bottom: 15px;
  clear: both;
  width: 90%;
}
.your-msg .msg-user-text {
  background: #f96332;
  color: #fff;
  text-align: left;
}
.your-msg {
  float: right;
  text-align: right;
}
.msg-header-action-option ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
.msg-header-action-option ul li {
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-align: left;
  line-height: initial;
}
.msg-header-action-option {
  position: absolute;
  top: 50px;
  right: 25px;
  border: 1px solid #efefef;
  padding: 15px;
  background: #fff;
  display: none;
  z-index: 1;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.toggle-is-open .msg-header-action-option {
  display: block;
}
.msg-header-action-option ul li:last-child {
  margin-bottom: 0;
}
.msg-header-action-option ul li:hover {
  color: #f96332;
  text-decoration: underline;
}
.new-msg-label {
  background: #f96332;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px !important;
  font-weight: 500;
  font-size: 12px;
  margin: 0 15px;
}
/* end chat */

/* icons */

@font-face {
  font-family: "icons";
  src: url("assets/fonts/icons.eot?72945082");
  src: url("assets/fonts/icons.eot?72945082#iefix") format("embedded-opentype"),
    url("assets/fonts/icons.woff2?72945082") format("woff2"),
    url("assets/fonts/icons.woff?72945082") format("woff"),
    url("assets/fonts/icons.ttf?72945082") format("truetype"),
    url("assets/fonts/icons.svg?72945082#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?72945082#fontello') format('svg');
    }
  }
  */

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-bell:before {
  content: "\e800";
} /* '' */
.icon-mail:before {
  content: "\e801";
} /* '' */
.icon-ok-circled2:before {
  content: "\e802";
} /* '' */
.icon-cancel-circled2:before {
  content: "\e803";
} /* '' */
.icon-cancel-circled:before {
  content: "\e804";
} /* '' */
.icon-down-open:before {
  content: "\e805";
} /* '' */
.icon-left-open:before {
  content: "\e806";
} /* '' */
.icon-right-open:before {
  content: "\e807";
} /* '' */
.icon-up-open:before {
  content: "\e808";
} /* '' */
.icon-down-dir:before {
  content: "\e809";
} /* '' */
.icon-up-dir:before {
  content: "\e80a";
} /* '' */
.icon-left-dir:before {
  content: "\e80b";
} /* '' */
.icon-right-dir:before {
  content: "\e80c";
} /* '' */
.icon-ok:before {
  content: "\e80d";
} /* '' */
.icon-cancel:before {
  content: "\e80e";
} /* '' */
.icon-dot-3:before {
  content: "\e80f";
} /* '' */
.icon-user-add-outline:before {
  content: "\e810";
} /* '' */
.icon-cog-outline:before {
  content: "\e811";
} /* '' */
.icon-cog-1:before {
  content: "\e812";
} /* '' */
.icon-search:before {
  content: "\e813";
} /* '' */
.icon-search-1:before {
  content: "\e814";
} /* '' */
.icon-plus:before {
  content: "\e815";
} /* '' */
.icon-minus:before {
  content: "\e816";
} /* '' */
.icon-plus-1:before {
  content: "\e817";
} /* '' */
.icon-cog:before {
  content: "\e818";
} /* '' */
.icon-minus-1:before {
  content: "\e819";
} /* '' */
.icon-arrow-combo:before {
  content: "\e81a";
} /* '' */
.icon-rfqs:before {
  content: "\e81b";
} /* '' */
.icon-cancel-squared:before {
  content: "\e81c";
} /* '' */
.icon-calendar:before {
  content: "\e81d";
} /* '' */
.icon-list-add:before {
  content: "\e81e";
} /* '' */
.icon-heart:before {
  content: "\e81f";
} /* '' */
.icon-heart-empty:before {
  content: "\e820";
} /* '' */
.icon-basket:before {
  content: "\e821";
} /* '' */
.icon-location:before {
  content: "\e822";
} /* '' */
.icon-location-1:before {
  content: "\e823";
} /* '' */
.icon-cancel-1:before {
  content: "\e824";
} /* '' */
.icon-clients_comp:before {
  content: "\e825";
} /* '' */
.icon-cms_comp:before {
  content: "\e826";
} /* '' */
.icon-commissions_comp:before {
  content: "\e827";
} /* '' */
.icon-dashboard_comp:before {
  content: "\e828";
} /* '' */
.icon-financial_comp:before {
  content: "\e829";
} /* '' */
.icon-help_comp:before {
  content: "\e82a";
} /* '' */
.icon-messaging_comp:before {
  content: "\e82b";
} /* '' */
.icon-orders_comp:before {
  content: "\e82c";
} /* '' */
.icon-providers_compound:before {
  content: "\e82d";
} /* '' */
.icon-calendar-1:before {
  content: "\e82e";
} /* '' */
.icon-services-comp:before {
  content: "\e82f";
} /* '' */
.icon-double-arrow:before {
  content: "\e830";
} /* '' */
.icon-calendar-2:before {
  content: "\e831";
} /* '' */
.icon-calendar-empty-1:before {
  content: "\e832";
} /* '' */
.icon-cart:before {
  content: "\e833";
} /* '' */
.icon-bell-1:before {
  content: "\e834";
} /* '' */
.icon-phone:before {
  content: "\e835";
} /* '' */
.icon-star:before {
  content: "\e836";
} /* '' */
.icon-star-empty:before {
  content: "\e837";
} /* '' */
.icon-star-half:before {
  content: "\e838";
} /* '' */
.icon-trash-empty:before {
  content: "\e839";
} /* '' */
.icon-trash:before {
  content: "\e83a";
} /* '' */
.icon-block:before {
  content: "\e83b";
} /* '' */
.icon-block-1:before {
  content: "\e83c";
} /* '' */
.icon-login:before {
  content: "\e83d";
} /* '' */
.icon-cog-outline-1:before {
  content: "\e83e";
} /* '' */
.icon-cog-2:before {
  content: "\e83f";
} /* '' */
.icon-edit:before {
  content: "\e840";
} /* '' */
.icon-pencil:before {
  content: "\e841";
} /* '' */
.icon-pencil-1:before {
  content: "\e842";
} /* '' */
.icon-floppy:before {
  content: "\e843";
} /* '' */
.icon-floppy-1:before {
  content: "\e844";
} /* '' */
.icon-th-large:before {
  content: "\e845";
} /* '' */
.icon-th-large-outline:before {
  content: "\e846";
} /* '' */
.icon-th:before {
  content: "\e847";
} /* '' */
.icon-upload:before {
  content: "\e848";
} /* '' */
.icon-eye:before {
  content: "\e849";
} /* '' */
.icon-eye-1:before {
  content: "\e84a";
} /* '' */
.icon-download:before {
  content: "\e84b";
} /* '' */
.icon-cancel-2:before {
  content: "\e84c";
} /* '' */
.icon-cancel-3:before {
  content: "\e84d";
} /* '' */
.icon-lock-reset:before {
  content: "\e84e";
} /* '' */
.icon-cloud-outline:before {
  content: "\e84f";
} /* '' */
.icon-credit-card:before {
  content: "\e850";
} /* '' */
.icon-record:before {
  content: "\e851";
} /* '' */
.icon-record-outline:before {
  content: "\e852";
} /* '' */
.icon-menu-1:before {
  content: "\e853";
} /* '' */
.icon-csv-icon:before {
  content: "\e854";
} /* '' */
.icon-info-circled:before {
  content: "\e855";
} /* '' */
.icon-exclamation:before {
  content: "\e856";
} /* '' */
.icon-attention:before {
  content: "\e857";
} /* '' */
.icon-attention-circled:before {
  content: "\e858";
} /* '' */
.icon-bell-2:before {
  content: "\e859";
} /* '' */
.icon-mail-1:before {
  content: "\e85a";
} /* '' */
.icon-ok-circled2-1:before {
  content: "\e85b";
} /* '' */
.icon-cancel-circled2-1:before {
  content: "\e85c";
} /* '' */
.icon-cancel-circled-1:before {
  content: "\e85d";
} /* '' */
.icon-down-open-1:before {
  content: "\e85e";
} /* '' */
.icon-left-open-1:before {
  content: "\e85f";
} /* '' */
.icon-right-open-1:before {
  content: "\e860";
} /* '' */
.icon-up-open-1:before {
  content: "\e861";
} /* '' */
.icon-down-dir-1:before {
  content: "\e862";
} /* '' */
.icon-up-dir-1:before {
  content: "\e863";
} /* '' */
.icon-left-dir-1:before {
  content: "\e864";
} /* '' */
.icon-right-dir-1:before {
  content: "\e865";
} /* '' */
.icon-area-orientation:before {
  content: "\e866";
} /* '' */
.icon-departure-assistance:before {
  content: "\e867";
} /* '' */
.icon-eu-citizen:before {
  content: "\e868";
} /* '' */
.icon-eu-forms:before {
  content: "\e869";
} /* '' */
.icon-house-search:before {
  content: "\e86a";
} /* '' */
.icon-immigration:before {
  content: "\e86b";
} /* '' */
.icon-inventory:before {
  content: "\e86c";
} /* '' */
.icon-moving:before {
  content: "\e86d";
} /* '' */
.icon-moving-services:before {
  content: "\e86e";
} /* '' */
.icon-non-eu-citizens:before {
  content: "\e86f";
} /* '' */
.icon-other-immigration:before {
  content: "\e870";
} /* '' */
.icon-relocation:before {
  content: "\e871";
} /* '' */
.icon-school-search:before {
  content: "\e872";
} /* '' */
.icon-settling-in:before {
  content: "\e873";
} /* '' */
.icon-storage:before {
  content: "\e874";
} /* '' */
.icon-taxation-arrival:before {
  content: "\e875";
} /* '' */
.icon-taxation-departure:before {
  content: "\e876";
} /* '' */
.icon-taxation-stay:before {
  content: "\e877";
} /* '' */
.icon-tax-services:before {
  content: "\e878";
} /* '' */
.icon-training:before {
  content: "\e879";
} /* '' */
.icon-visa-support:before {
  content: "\e87a";
} /* '' */
.icon-ok-1:before {
  content: "\e87b";
} /* '' */
.icon-cancel-4:before {
  content: "\e87c";
} /* '' */
.icon-dot-1:before {
  content: "\e87d";
} /* '' */
.icon-user-add-outline-1:before {
  content: "\e87e";
} /* '' */
.icon-cog-outline-2:before {
  content: "\e87f";
} /* '' */
.icon-cog-3:before {
  content: "\e880";
} /* '' */
.icon-search-2:before {
  content: "\e881";
} /* '' */
.icon-search-3:before {
  content: "\e882";
} /* '' */
.icon-plus-2:before {
  content: "\e883";
} /* '' */
.icon-minus-2:before {
  content: "\e884";
} /* '' */
.icon-plus-3:before {
  content: "\e885";
} /* '' */
.icon-cog-4:before {
  content: "\e886";
} /* '' */
.icon-minus-3:before {
  content: "\e887";
} /* '' */
.icon-arrow-combo-1:before {
  content: "\e888";
} /* '' */
.icon-view-more:before {
  content: "\e889";
} /* '' */
.icon-rfqs-1:before {
  content: "\e88a";
} /* '' */
.icon-cancel-squared-1:before {
  content: "\e88b";
} /* '' */
.icon-calendar-3:before {
  content: "\e88c";
} /* '' */
.icon-list-add-1:before {
  content: "\e88d";
} /* '' */
.icon-heart-1:before {
  content: "\e88e";
} /* '' */
.icon-heart-empty-1:before {
  content: "\e88f";
} /* '' */
.icon-basket-1:before {
  content: "\e890";
} /* '' */
.icon-location-2:before {
  content: "\e891";
} /* '' */
.icon-location-3:before {
  content: "\e892";
} /* '' */
.icon-cancel-5:before {
  content: "\e893";
} /* '' */
.icon-clients_comp-1:before {
  content: "\e894";
} /* '' */
.icon-cms_comp-1:before {
  content: "\e895";
} /* '' */
.icon-commissions_comp-1:before {
  content: "\e896";
} /* '' */
.icon-dashboard_comp-1:before {
  content: "\e897";
} /* '' */
.icon-financial_comp-1:before {
  content: "\e898";
} /* '' */
.icon-help_comp-1:before {
  content: "\e899";
} /* '' */
.icon-messaging_comp-1:before {
  content: "\e89a";
} /* '' */
.icon-orders_comp-1:before {
  content: "\e89b";
} /* '' */
.icon-providers_compound-1:before {
  content: "\e89c";
} /* '' */
.icon-calendar-4:before {
  content: "\e89d";
} /* '' */
.icon-services-comp-1:before {
  content: "\e89e";
} /* '' */
.icon-double-arrow-1:before {
  content: "\e89f";
} /* '' */
.icon-calendar-5:before {
  content: "\e8a0";
} /* '' */
.icon-calendar-empty-2:before {
  content: "\e8a1";
} /* '' */
.icon-cart-1:before {
  content: "\e8a2";
} /* '' */
.icon-bell-3:before {
  content: "\e8a3";
} /* '' */
.icon-phone-1:before {
  content: "\e8a4";
} /* '' */
.icon-star-1:before {
  content: "\e8a5";
} /* '' */
.icon-star-empty-1:before {
  content: "\e8a6";
} /* '' */
.icon-star-half-1:before {
  content: "\e8a7";
} /* '' */
.icon-trash-empty-1:before {
  content: "\e8a8";
} /* '' */
.icon-trash-1:before {
  content: "\e8a9";
} /* '' */
.icon-block-2:before {
  content: "\e8aa";
} /* '' */
.icon-block-3:before {
  content: "\e8ab";
} /* '' */
.icon-login-1:before {
  content: "\e8ac";
} /* '' */
.icon-cog-outline-3:before {
  content: "\e8ad";
} /* '' */
.icon-cog-5:before {
  content: "\e8ae";
} /* '' */
.icon-edit-1:before {
  content: "\e8af";
} /* '' */
.icon-pencil-2:before {
  content: "\e8b0";
} /* '' */
.icon-pencil-3:before {
  content: "\e8b1";
} /* '' */
.icon-floppy-2:before {
  content: "\e8b2";
} /* '' */
.icon-floppy-3:before {
  content: "\e8b3";
} /* '' */
.icon-th-large-1:before {
  content: "\e8b4";
} /* '' */
.icon-th-large-outline-1:before {
  content: "\e8b5";
} /* '' */
.icon-th-1:before {
  content: "\e8b6";
} /* '' */
.icon-upload-1:before {
  content: "\e8b7";
} /* '' */
.icon-eye-2:before {
  content: "\e8b8";
} /* '' */
.icon-eye-3:before {
  content: "\e8b9";
} /* '' */
.icon-download-1:before {
  content: "\e8ba";
} /* '' */
.icon-cancel-6:before {
  content: "\e8bb";
} /* '' */
.icon-cancel-7:before {
  content: "\e8bc";
} /* '' */
.icon-lock-reset-1:before {
  content: "\e8bd";
} /* '' */
.icon-cloud-outline-1:before {
  content: "\e8be";
} /* '' */
.icon-credit-card-1:before {
  content: "\e8bf";
} /* '' */
.icon-record-1:before {
  content: "\e8c0";
} /* '' */
.icon-record-outline-1:before {
  content: "\e8c1";
} /* '' */
.icon-menu-2:before {
  content: "\e8c2";
} /* '' */
.icon-attention-1:before {
  content: "\e8c3";
} /* '' */
.icon-attention-2:before {
  content: "\e8c4";
} /* '' */
.icon-attention-circled-1:before {
  content: "\e8c5";
} /* '' */
.icon-area-orientation-1:before {
  content: "\e8c6";
} /* '' */
.icon-departure-assistance-1:before {
  content: "\e8c7";
} /* '' */
.icon-eu-citizen-1:before {
  content: "\e8c8";
} /* '' */
.icon-eu-forms-1:before {
  content: "\e8c9";
} /* '' */
.icon-house-search-1:before {
  content: "\e8ca";
} /* '' */
.icon-immigration-1:before {
  content: "\e8cb";
} /* '' */
.icon-inventory-1:before {
  content: "\e8cc";
} /* '' */
.icon-moving-1:before {
  content: "\e8cd";
} /* '' */
.icon-moving-services-1:before {
  content: "\e8ce";
} /* '' */
.icon-non-eu-citizens-1:before {
  content: "\e8cf";
} /* '' */
.icon-other-immigration-1:before {
  content: "\e8d0";
} /* '' */
.icon-relocation-1:before {
  content: "\e8d1";
} /* '' */
.icon-school-search-1:before {
  content: "\e8d2";
} /* '' */
.icon-settling-in-1:before {
  content: "\e8d3";
} /* '' */
.icon-storage-1:before {
  content: "\e8d4";
} /* '' */
.icon-taxation-arrival-1:before {
  content: "\e8d5";
} /* '' */
.icon-taxation-departure-1:before {
  content: "\e8d6";
} /* '' */
.icon-taxation-stay-1:before {
  content: "\e8d7";
} /* '' */
.icon-tax-services-1:before {
  content: "\e8d8";
} /* '' */
.icon-training-1:before {
  content: "\e8d9";
} /* '' */
.icon-visa-support-1:before {
  content: "\e8da";
} /* '' */
.icon-lease_contract_termination:before {
  content: "\e8db";
} /* '' */
.icon-uitility_contracts:before {
  content: "\e8dc";
} /* '' */
.icon-airport_transfer:before {
  content: "\e8dd";
} /* '' */
.icon-car_registrations:before {
  content: "\e8de";
} /* '' */
.icon-banking_de_registration:before {
  content: "\e8df";
} /* '' */
.icon-contract_termination:before {
  content: "\e8e0";
} /* '' */
.icon-departure_asssitance:before {
  content: "\e8e1";
} /* '' */
.icon-transportation_assistance_leaving:before {
  content: "\e8e2";
} /* '' */
.icon-check_in_assistance:before {
  content: "\e8e3";
} /* '' */
.icon-house_hunt:before {
  content: "\e8e4";
} /* '' */
.icon-lease_negotiation:before {
  content: "\e8e5";
} /* '' */
.icon-lease_renewal:before {
  content: "\e8e6";
} /* '' */
.icon-temporary_housing:before {
  content: "\e8e7";
} /* '' */
.icon-furniture_rental:before {
  content: "\e8e8";
} /* '' */
.icon-other:before {
  content: "\e8e9";
} /* '' */
.icon-tenancy:before {
  content: "\e8ea";
} /* '' */
.icon-utilities:before {
  content: "\e8eb";
} /* '' */
.icon-check_in_assistance-1:before {
  content: "\e8ec";
} /* '' */
.icon-domestic_support:before {
  content: "\e8ed";
} /* '' */
.icon-medical_assistance:before {
  content: "\e8ee";
} /* '' */
.icon-opening_bank_account:before {
  content: "\e8ef";
} /* '' */
.icon-spouse_support:before {
  content: "\e8f0";
} /* '' */
.icon-transportation_assistance:before {
  content: "\e8f1";
} /* '' */
.icon-intercultural_trainings:before {
  content: "\e8f2";
} /* '' */
.icon-language_trainings:before {
  content: "\e8f3";
} /* '' */
.icon-area_orientation:before {
  content: "\e8f4";
} /* '' */
.icon-airport_transfer-1:before {
  content: "\e8f5";
} /* '' */
.icon-car_registrations-1:before {
  content: "\e8f6";
} /* '' */
.icon-car_rental:before {
  content: "\e8f7";
} /* '' */
.icon-de_registration:before {
  content: "\e8f8";
} /* '' */
.icon-renewal:before {
  content: "\e8f9";
} /* '' */
.icon-residence_change:before {
  content: "\e8fa";
} /* '' */
.icon-other-1:before {
  content: "\e8fb";
} /* '' */
.icon-permanent_residence:before {
  content: "\e8fc";
} /* '' */
.icon-residence_cancel:before {
  content: "\e8fd";
} /* '' */
.icon-residence_change-1:before {
  content: "\e8fe";
} /* '' */
.icon-residence_permit:before {
  content: "\e8ff";
} /* '' */
.icon-residence_renewal:before {
  content: "\e900";
} /* '' */
.icon-work_authorization-copy:before {
  content: "\e901";
} /* '' */
.icon-work_renewal:before {
  content: "\e902";
} /* '' */
.icon-approval_family_members:before {
  content: "\e903";
} /* '' */
.icon-residence_permit_non_eu:before {
  content: "\e904";
} /* '' */
.icon-work_authorization:before {
  content: "\e905";
} /* '' */
.icon-apostille_process:before {
  content: "\e906";
} /* '' */
.icon-child_support:before {
  content: "\e907";
} /* '' */
.icon-company_documents:before {
  content: "\e908";
} /* '' */
.icon-notary_assistance:before {
  content: "\e909";
} /* '' */
.icon-police_clerance:before {
  content: "\e90a";
} /* '' */
.icon-study_recognition:before {
  content: "\e90b";
} /* '' */
.icon-super_authentication:before {
  content: "\e90c";
} /* '' */
.icon-translation_and_legalisation:before {
  content: "\e90d";
} /* '' */
.icon-other-copy:before {
  content: "\e90e";
} /* '' */
.icon-visa_family:before {
  content: "\e90f";
} /* '' */
.icon-visa_renewal:before {
  content: "\e910";
} /* '' */
.icon-visa_secondament:before {
  content: "\e911";
} /* '' */
.icon-work:before {
  content: "\e912";
} /* '' */
.icon-invitation_letter_support:before {
  content: "\e913";
} /* '' */
.icon-visa_business:before {
  content: "\e914";
} /* '' */
.icon-visa_pleasure:before {
  content: "\e915";
} /* '' */
.icon-long_term_visa:before {
  content: "\e916";
} /* '' */
.icon-short_term_visa:before {
  content: "\e917";
} /* '' */
.icon-surveys:before {
  content: "\e918";
} /* '' */
.icon-long-temr:before {
  content: "\e919";
} /* '' */
.icon-short_term:before {
  content: "\e91a";
} /* '' */
.icon-art:before {
  content: "\e91b";
} /* '' */
.icon-furniture:before {
  content: "\e91c";
} /* '' */
.icon-pet:before {
  content: "\e91d";
} /* '' */
.icon-vehicles:before {
  content: "\e91e";
} /* '' */
.icon-a1_form:before {
  content: "\e91f";
} /* '' */
.icon-health_card:before {
  content: "\e920";
} /* '' */
.icon-other_form:before {
  content: "\e921";
} /* '' */
.icon-s1_form:before {
  content: "\e922";
} /* '' */
.icon-exit_certificate:before {
  content: "\e923";
} /* '' */
.icon-exit_tax:before {
  content: "\e924";
} /* '' */
.icon-labour_notification-copy-2:before {
  content: "\e925";
} /* '' */
.icon-retirement_contributions-copy:before {
  content: "\e926";
} /* '' */
.icon-tax_interview-copy:before {
  content: "\e927";
} /* '' */
.icon-tax_residency-copy:before {
  content: "\e928";
} /* '' */
.icon-anual_tax_declaration:before {
  content: "\e929";
} /* '' */
.icon-labour_notification-copy:before {
  content: "\e92a";
} /* '' */
.icon-retirement_contributions:before {
  content: "\e92b";
} /* '' */
.icon-tax_calculator:before {
  content: "\e92c";
} /* '' */
.icon-tax_certificate:before {
  content: "\e92d";
} /* '' */
.icon-labour_notification:before {
  content: "\e92e";
} /* '' */
.icon-social_security:before {
  content: "\e92f";
} /* '' */
.icon-tax_interview:before {
  content: "\e930";
} /* '' */
.icon-tax_number:before {
  content: "\e931";
} /* '' */
.icon-tax_registration:before {
  content: "\e932";
} /* '' */
.icon-tax_residency:before {
  content: "\e933";
} /* '' */
.icon-twitter-1:before {
  content: "\e934";
} /* '' */
.icon-facebook-1:before {
  content: "\e935";
} /* '' */
.icon-filter-1:before {
  content: "\e936";
} /* '' */
.icon-menu-3:before {
  content: "\e937";
} /* '' */
.icon-list-numbered-1:before {
  content: "\e938";
} /* '' */
.icon-gplus-2:before {
  content: "\e939";
} /* '' */
.icon-linkedin-1:before {
  content: "\e93a";
} /* '' */
.icon-download-cloud-1:before {
  content: "\e93b";
} /* '' */
.icon-upload-cloud-1:before {
  content: "\e93c";
} /* '' */
.icon-plus-squared-1:before {
  content: "\e93d";
} /* '' */
.icon-angle-left-1:before {
  content: "\e93e";
} /* '' */
.icon-angle-right-1:before {
  content: "\e93f";
} /* '' */
.icon-angle-up-1:before {
  content: "\e940";
} /* '' */
.icon-angle-down-1:before {
  content: "\e941";
} /* '' */
.icon-star-half-alt-1:before {
  content: "\e942";
} /* '' */
.icon-calendar-empty-3:before {
  content: "\e943";
} /* '' */
.icon-minus-squared-1:before {
  content: "\e944";
} /* '' */
.icon-minus-squared-alt-1:before {
  content: "\e945";
} /* '' */
.icon-down-1:before {
  content: "\e946";
} /* '' */
.icon-up-1:before {
  content: "\e947";
} /* '' */
.icon-left-1:before {
  content: "\e948";
} /* '' */
.icon-right-1:before {
  content: "\e949";
} /* '' */
.icon-plus-squared-alt-1:before {
  content: "\e94a";
} /* '' */
.icon-copyright-1:before {
  content: "\e94b";
} /* '' */
.icon-linkedin-squared-1:before {
  content: "\e94c";
} /* '' */
.icon-gplus-3:before {
  content: "\e94d";
} /* '' */
.icon-heart-broken:before {
  content: "\f028";
} /* '' */
.icon-twitter:before {
  content: "\f099";
} /* '' */
.icon-facebook:before {
  content: "\f09a";
} /* '' */
.icon-filter:before {
  content: "\f0b0";
} /* '' */
.icon-menu:before {
  content: "\f0c9";
} /* '' */
.icon-list-numbered:before {
  content: "\f0cb";
} /* '' */
.icon-gplus:before {
  content: "\f0d5";
} /* '' */
.icon-linkedin:before {
  content: "\f0e1";
} /* '' */
.icon-download-cloud:before {
  content: "\f0ed";
} /* '' */
.icon-upload-cloud:before {
  content: "\f0ee";
} /* '' */
.icon-plus-squared:before {
  content: "\f0fe";
} /* '' */
.icon-angle-left:before {
  content: "\f104";
} /* '' */
.icon-angle-right:before {
  content: "\f105";
} /* '' */
.icon-angle-up:before {
  content: "\f106";
} /* '' */
.icon-angle-down:before {
  content: "\f107";
} /* '' */
.icon-star-half-alt:before {
  content: "\f123";
} /* '' */
.icon-attention-alt:before {
  content: "\f12a";
} /* '' */
.icon-calendar-empty:before {
  content: "\f133";
} /* '' */
.icon-minus-squared:before {
  content: "\f146";
} /* '' */
.icon-minus-squared-alt:before {
  content: "\f147";
} /* '' */
.icon-down:before {
  content: "\f175";
} /* '' */
.icon-up:before {
  content: "\f176";
} /* '' */
.icon-left:before {
  content: "\f177";
} /* '' */
.icon-right:before {
  content: "\f178";
} /* '' */
.icon-plus-squared-alt:before {
  content: "\f196";
} /* '' */
.icon-file-pdf:before {
  content: "\f1c1";
} /* '' */
.icon-newspaper:before {
  content: "\f1ea";
} /* '' */
.icon-copyright:before {
  content: "\f1f9";
} /* '' */
.icon-linkedin-squared:before {
  content: "\f30c";
} /* '' */
.icon-gplus-1:before {
  content: "\f30f";
} /* '' */

@media (max-width: 1700px) {
  .w-m-40 {
    width: 40% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .w-m-50 {
    width: 50% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .w-m-60 {
    width: 60% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .w-m-100 {
    width: 100% !important;
    float: left;
    padding-right: 15px;
    padding-left: 15px;
  }
  .sts-w-m-m-b {
    margin-bottom: 30px;
  }
  .row-m {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 1600px) {
  /* td.ta-r,
  th.ta-r {
    text-align: left !important;
  } */
  .mat-sort-header-arrow {
    -webkit-transform: translateY(-4px) !important;
    transform: translateY(-4px) !important;
  }
  input.checkbox-switch:after {
    width: 20px;
    height: 20px;
  }
  input.checkbox-switch {
    width: 45px;
    height: 16px;
  }
}

@media (max-width: 1440px) {
  .auth-form-wrapper {
    padding: 0 50px;
  }
  .auth-left-inner .logo {
    margin: 50px auto 0;
  }
  .login-footer {
    margin: 0 50px 0px 50px !important;
    bottom: 80px;
  }

  .table-wrapper table thead th,
  .table-wrapper table tbody tr td {
    font-size: 13px;
  }
  .table-wrapper table tbody tr td {
    padding: 12px;
  }
  .table-wrapper table thead tr th {
    padding: 12px;
  }

  .mat-sort-header-arrow {
    -webkit-transform: translateY(-2px) !important;
    transform: translateY(-2px) !important;
  }
  table .mat-checkbox-inner-container {
    width: 16px !important;
    height: 16px !important;
  }
  body {
    font-size: 13px;
  }
  .dashboard-body {
    padding: 30px 30px;
  }
  .header-wrapper-no-bg .header-top {
    padding: 15px 30px;
  }
  .small-fixed-header-admin .header-top {
    padding: 0 30px;
  }
  .left-navigation-wrapper {
    width: 230px;
  }
  .left-navigation-elem-wrapper {
    width: 230px;
  }
  .dashboard-wrapper {
    padding: 0 0 0 230px;
  }
  .btn-small {
    font-size: 13px;
  }
  .table-wrapper table tbody tr td:first-child,
  .table-wrapper table thead tr th:first-child {
    padding-left: 10px;
  }
  #header-wrapper-portal,
  #header-wrapper-admin {
    width: calc(100% - 230px);
  }
  .header-top {
    font-size: 13px;
  }
  body {
    font-size: 13px;
  }
  .small-dashboard-body-top,
  .small-dashboard-body-action,
  .small-table-bulk,
  .small-app-nav {
    width: calc(100% - 290px) !important;
  }

  .statistics-tab-wrapper {
    font-size: 13px;
  }
  .big-title {
    font-size: 22px;
  }
  .dashboard-statistics-box-title {
    font-size: 14px;
  }
  .fs-20 {
    font-size: 18px !important;
  }
  .fs-16 {
    font-size: 14px !important;
  }
  .statistics-table table thead tr th {
    font-size: 14px;
  }
  .medium-title {
    font-size: 16px;
  }
  .white-box-body,
  .white-box-header-action,
  .action-link-gray,
  .action-link-orange,
  .category-tree-row,
  .order-details-box-wrapper,
  .order-details-box-link ul li {
    font-size: 13px;
  }

  .modal-title,
  .custom-modal-wrapper .modal-title-custom {
    font-size: 22px;
  }

  .w-10,
  .w-20,
  .w-30,
  .w-40,
  .w-50,
  .w-60,
  .w-70,
  .w-80,
  .w-90,
  .w-100,
  .w-33 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  .details-navigation .mat-tab-link {
    margin-left: 10px;
    font-size: 13px;
  }

  .modal-step-wrapper ul li,
  .modal-step-wrapper ul li.active-step {
    font-size: 14px;
  }
  .bulk-action-wrapper {
    padding: 10px 15px;
  }
}

@media (max-width: 1200px) {
  .auth-footer {
    margin: 0 50px -120px 50px;
    font-size: 12px;
    padding-bottom: 20px;
  }

  .login-footer {
    margin: 0 50px 0px 50px !important;
    bottom: 80px;
  }
  .auth-form-wrapper {
    padding: 0 40px;
  }
  .auth-left-inner .logo {
    margin: 40px auto 0;
  }
  .login-footer {
    margin: 0 40px 0px 40px !important;
    bottom: 80px;
  }
  .left-navigation-wrapper {
    width: 210px;
  }
  .left-navigation-elem-wrapper {
    width: 210px;
  }
  .left-navigation-wrapper .logo {
    width: 180px;
  }
  .dashboard-wrapper {
    padding: 0 0 0 210px;
  }
  #header-wrapper-portal,
  #header-wrapper-admin {
    width: calc(100% - 210px);
  }
  .small-dashboard-body-top,
  .small-dashboard-body-action,
  .small-table-bulk,
  .small-app-nav {
    width: calc(100% - 270px) !important;
  }
  .details-navigation .mat-tab-link {
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .small-box-details span {
    display: block;
  }

  .details-navigation .mat-ink-bar {
    display: none;
  }

  .details-navigation {
    height: auto;
  }
  .dashboard-body-action {
    height: auto;
  }
  ng-select.customiz-select {
    width: 123% !important;
    transform: scale(0.81);
    transform-origin: left top;
    font-size: 16px;
  }

  ng-select.customiz-select .ng-select-container {
    height: 44px !important;
    line-height: 44px !important;
  }

  ng-select.customiz-select .ng-select-container .ng-input {
    height: 44px !important;
    line-height: 44px !important;
  }

  ng-select.customiz-select
    .ng-select-container
    .ng-value-container
    .ng-input
    > input {
    height: 44px !important;
    line-height: 44px !important;
    font-size: 16px !important;
  }

  ng-select.customiz-select.ng-select .ng-clear-wrapper .ng-clear {
    line-height: 44px !important;
  }
  .customiz-selectng-select span,
  ng-select.customiz-select div {
    font-size: 16px !important;
    color: #333 !important;
  }
  .search-box input {
    margin-bottom: 0;
  }

  .logo img {
    width: 100px;
  }
  .mobile .header-top-user-info,
  .mobile .header-top-user-img {
    display: none;
  }
  .header-top-user-img-responsive {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(10px, -50%);
  }
  .header-top-user-img-responsive img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: all 0.3s ease-out;
  }
  .header-top-user-details-responsive {
    padding: 15px;
    border-bottom: 1px solid #efefef;
    font-weight: 500;
    position: relative;
  }
  .header-top-user-info-responsive {
    margin-left: 35px;
  }
  .carousel .content .custom-item .img,
  .top-banner-right {
    height: 380px;
  }
  .top-banner-right {
    float: none;
    width: 100%;
  }

  .open-child-menu {
    position: absolute;
    width: 28px;
    font-size: 28px;
    line-height: 28px;
    margin: 0;
    top: 50%;
    right: 25px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  .header-bottom ng-select.customiz-select .ng-select-container {
    height: 44px !important;
    line-height: 44px;
  }
  .header-bottom
    ng-select.customiz-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input,
  .header-bottom
    ng-select.customiz-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    height: 44px;
    line-height: 44px;
    padding: 0 15px 0 30px;
  }
  ng-select.customiz-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input,
  ng-select.customiz-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    padding: 0 50px 0 25px;
    height: 44px;
    line-height: 44px;
  }
  .header-bottom
    ng-select.customiz-select
    .ng-select-container
    .ng-value-container
    .ng-input
    > input {
    height: 44px;
    line-height: 44px;
  }
  .ng-select .ng-select-container {
    min-height: 44px;
  }
  .header-bottom-action-item {
    margin-left: 0;
  }
  ng-select.location-select {
    width: 100%;
  }
  .homepage .search-box-header-wrapper {
    width: 100%;
  }
  .header-bottom {
    padding: 15px 0;
    background: #fff;
  }
  .header-bottom .row {
    margin-bottom: 15px;
  }
  .header-bottom .row:last-child {
    margin-bottom: 0;
  }
  /* .search-box:before {
  top: 8px;
} */
  .mobile .header-bottom-action {
    width: 100%;
  }
  .wrapper-fix {
    margin-top: 174px;
  }
  .other-items-mobile-wrapper {
    background: #fff;
    display: inline-block;
    position: fixed;
    top: 0;
    z-index: 9999;
    right: 0;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 100%;
    margin-right: -280px;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    width: 240px;
    padding: 15px;
  }
  .auth-header-xs-wrapper {
    background: #fff;
    display: inline-block;
    position: fixed;
    top: 0;
    z-index: 9999;
    right: 0;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    height: 100%;
    margin-right: -280px;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    width: 240px;
    padding: 15px;
  }

  .right-side {
    padding-left: 0;
    width: 100%;
  }
  #scrollToTop {
    display: none !important;
  }
  .main-title {
    font-size: 22px;
    line-height: 22px;
  }

  .open-other {
    margin-right: 0;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .close-mobile-other,
  .close-mobile-tools {
    position: fixed;
    top: 40px;
    right: 240px;
    z-index: 10001;
    background: #fff;
    padding: 5px;
    margin-left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    margin-right: -280px;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .close-mobile-other i:before,
  .close-mobile-tools i:before {
    font-size: 22px;
  }
  .other-items-mobile-wrapper .select-language-wrapper ng-select.lang-select {
    width: 100%;
  }
  .other-items-mobile-wrapper .form-group {
    margin-bottom: 15px;
  }
  .open-other-items {
    color: #fff;
    font-size: 16px;
    line-height: initial;
    padding: 0;
    width: auto;
    margin: 0;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    background: #f96332;
    border-radius: 4px;
  }
  .open-mobile-fiter {
    float: right;
    margin: 15px 0;
  }
  ng-select.customiz-select .ng-select-container {
    height: 44px !important;
    line-height: 44px !important;
  }
  ng-select.no-clearable-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input,
  ng-select.no-clearable-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    padding: 0 20px 0 15px !important;
    height: 44px;
    line-height: 44px;
  }
  ng-select.customiz-select
    .ng-select-container
    .ng-value-container
    .ng-input
    > input {
    font-size: 16px;
    height: 44px;
    line-height: 44px;
    padding: 0;
  }
  ng-select.customiz-select.ng-select .ng-clear-wrapper .ng-clear {
    line-height: 44px !important;
  }
  .card-title {
    font-size: 16px;
    line-height: 16px;
    height: auto;
  }
  .card-cart i {
    font-size: 22px;
  }

  ng-select.location-select:before {
    position: absolute;
    top: 12px;
    left: 8px;
  }

  .banner-description-absolute-center {
    width: 100%;
    padding: 15px;
  }

  .logo,
  .header-top-action-wrapper,
  .header-top-user,
  .header-top-action-wrapper-no-logged {
    height: 40px;
  }
  .header-wrapper:before {
    top: 50px;
  }
  .notification-item i {
    font-size: 20px;
  }
  .header-overlay-top {
    font-size: 14px;
    padding: 10px;
  }
  .header-overlay-item {
    padding: 10px;
  }
  .header-overlay-bigtxt {
    font-size: 14px;
  }
  .header-overlay-img img {
    width: 50px;
    height: 50px;
  }
  .header-overlay-item-body {
    margin-left: 10px;
    width: calc(100% - 60px);
  }
  .header-overlay-wrapper {
    width: 100vw !important;
    max-width: 100vw !important;
  }
  .header-overlay-item-body .cart-item-details-range-wrapper {
    margin-bottom: 0;
  }
  .cart-item-details-range button i {
    font-size: 18px;
  }
  .cart-item-details-range button:first-child,
  .cart-item-details-range button:last-child {
    margin: 0 5px;
  }
  .header-overlay-total-cart-price {
    font-size: 18px;
    line-height: 18px;
  }
  .header-overlay-bottom {
    padding: 10px;
  }
  .header-overlay-checkout-cart {
    padding: 7px 14px;
    margin-top: 15px;
  }
  .notification-empty {
    padding: 10px;
  }
  .breadcrumbs-separator {
    padding-right: 5px;
  }
  .breadcrumbs-item {
    padding-right: 5px;
  }
  .content-left-side {
    width: 100%;
    float: none;
  }
  .content-right-side {
    width: 100%;
    float: none;
    margin: 15px 0;
  }
  .custom-tab-wrapper {
    padding: 15px;
  }
  .custom-tab-wrapper .mat-tab-label {
    min-width: 120px !important;
    height: auto !important;
    padding: 0 10px 10px !important;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    height: 3px;
  }
  .custom-tab-wrapper .mat-tab-label-content {
    font-size: 15px;
    font-weight: 600;
  }
  .tab-details,
  .tab-review-overall,
  .tab-card-list-wrapper {
    padding: 20px 0;
  }
  .no-padding {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .stars-bar-middle {
    width: 70%;
    margin: 3px 5px 7px 5px;
  }
  .custom-tab-wrapper .star {
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }
  .review-box-wrapper {
    padding: 20px 0;
  }
  .review-box-left {
    width: 100%;
    float: none;
    margin-bottom: 15px;
  }
  .review-box-left img {
    width: 60px;
    height: 60px;
    float: left;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .review-box-right {
    width: 100%;
    float: none;
  }
  .custom-tab-wrapper app-rating {
    margin-bottom: 10px;
  }

  .mobile .container-fix {
    overflow-x: hidden;
  }
  .header-top {
    padding: 5px 0;
  }

  /* .auth-left-wrapper {
  width: 100%;
  float: none;
} */
  .auth-right-wrapper {
    background: none !important;
    position: relative !important;
    width: 100%;
  }
  .auth-right-inner {
    position: relative !important;
    background: #fff;
    transform: none;
    left: 0;
    top: 0;
    width: 100%;
    padding: 25px;
    border-top: 1px solid #f6f6f6;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.03);
  }
  .auth-right-title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
  }
  .auth-right-line {
    width: 45px;
    height: 3px;
    background: #f96332;
    border-radius: 1px;
    margin: 10px 0;
  }
  .auth-right-desc {
    font-size: 14px;
    color: #333;
  }
  .auth-right-inner ul li {
    margin-bottom: 15px;
  }
  .auth-left-inner .logo {
    width: 180px;
  }
  .auth-form-wrapper {
    padding: 0 25px;
    margin: 30px 0;
  }
  .auth-form-title {
    font-size: 26px;
    margin-bottom: 25px;
  }
  .login-footer {
    margin: 0 25px 0px 25px !important;
  }
  .auth-left-inner .logo {
    margin: 50px 25px 0 25px;
  }
  .form-control {
    height: 45px;
    line-height: 45px;
  }
  .open-auth-header {
    float: right;
    height: 40px;
    line-height: 40px;
  }
  .open-auth-header i:before {
    font-size: 20px;
  }
  .auth-header-xs-wrapper.open-filter .header-top-action-wrapper-no-logged {
    height: 100%;
    float: none;
  }
  .auth-header-xs-wrapper.open-filter .header-top-action-no-logged {
    transform: none;
    top: 0;
  }
  .auth-header-xs-wrapper.open-filter .header-top-action-no-logged a {
    display: block;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .select-coutry-banner {
    padding: 15px;
    font-size: 14px;
  }
  .alert-wrapper,
  .notification-error-wrapper {
    width: 90%;
  }
  .mat-dialog-container {
    padding: 15px !important;
  }
  .custom-modal-wrapper .modal-title-custom {
    font-size: 20px;
    line-height: 20px;
  }
  .custom-modal-wrapper .modal-body-custom {
    margin: 20px 0;
  }
  .cart-item-details-title {
    font-size: 16px;
    line-height: 16px;
    min-height: auto;
    margin-bottom: 5px;
  }
  .cart-item-details-left {
    margin-left: 10px;
    width: calc(100% - 225px);
  }
  .cart-left-side {
    width: 100%;
    float: none;
  }
  .cart-item-title {
    font-size: 16px;
    padding: 15px;
    line-height: 16px;
  }
  .cart-item-body p {
    font-size: 14px;
  }
  .cart-item-body {
    padding: 15px;
  }
  .cart-item-footer {
    padding: 15px;
  }
  .cart-item-footer p span:last-child {
    font-size: 18px;
  }
  .cart-item-footer p {
    line-height: 18px;
  }
  .cart-right-side {
    width: 100%;
    float: none;
    margin: 15px 0;
  }
  .cart-steps-header {
    padding: 15px;
  }
  .cart-steps-header ul li a {
    margin-right: 30px;
  }
  .cart-steps-header ul li a.active:before {
    height: 3px;
    bottom: -14px;
  }
  .cart-item-details.ng-star-inserted {
    padding: 15px;
  }
  .cart-item-details-foote-bottom {
    padding: 15px;
  }
  .cart-item-details-foote-bottom span:first-child {
    font-size: 20px;
    line-height: 37px;
  }
  .cart-item-details-foote-bottom span:first-child {
    font-size: 20px;
    line-height: 37px;
  }

  .cart-step-box-wrapper {
    padding: 15px;
  }
  .cart-box-title {
    font-size: 16px;
    line-height: 16px;
  }
  .cart-box-title-action {
    font-size: 14px;
    margin-top: 5px;
    float: left;
    display: block;
    width: 100%;
  }
  .cart-box-address-add {
    margin: 15px 0;
  }
  .mat-radio-inner-circle {
    height: 15px !important;
    width: 15px !important;
  }
  .mat-radio-outer-circle {
    height: 15px !important;
    width: 15px !important;
  }
  .mat-radio-container {
    width: 15px !important;
    height: 15px !important;
  }

  .content-top-wrapper {
    margin-bottom: 10px;
  }
  .card-wrapper {
    width: 50%;
  }
  .card-title {
    font-size: 16px;
    line-height: 16px;
    height: 32px;
    overflow: hidden;
  }
  .content-left-side .card-wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .tab-card-list-wrapper .card-wrapper {
    width: 50%;
  }
  .notification-item {
    padding: 0 15px;
  }
  .notification-count {
    position: absolute;
    top: -5px;
    right: 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }
  .small-fixed-header-admin .notification-count,
  .small-fixed-header-market .notification-count {
    width: 18px;
    height: 18px;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .content-left-side .card-wrapper .card-title {
    height: auto;
  }
  .center-helper-mobile {
    text-align: center;
  }
  .center-helper-mobile .btn {
    float: none;
  }
  .ve-al-txt .btn {
    width: 45%;
  }

  .category-tree-row {
    margin: 0;
  }
  .btn {
    padding: 8px 16px;
  }
  .w-10,
  .w-20,
  .w-30,
  .w-40,
  .w-50,
  .w-60,
  .w-70,
  .w-80,
  .w-90,
  .w-100,
  .w-33,
  .w-25,
  .w-m-50 {
    width: 100% !important;
  }
  .mobile-header-middle-wrapper .w-30 {
    width: 30% !important;
  }
  .mobile-header-middle-wrapper .w-40 {
    width: 40% !important;
  }
  .header-breadcrumbs {
    display: none;
  }
  .dashboard-wrapper {
    padding: 0;
  }
  #header-wrapper-admin {
    width: 100%;
  }
  .header-wrapper-no-bg .header-top {
    padding: 5px 15px;
  }
  .mobile #header-wrapper-admin {
    background: #fff;
  }
  .mobile-header-middle-wrapper {
    position: fixed;
    background: #fff;
    top: 51px;
    width: 100%;
    padding: 7px 0;
    z-index: 99;
    border-bottom: 1px solid #efefef;
  }
  .mobile-menu-btn {
    background: #f0f0f0;
    color: #708295;
    padding: 8px 10px;
  }
  .mobile-header-middle-wrapper .header-top-lang {
    top: 0;
    transform: none;
    margin-left: 0;
    float: right;
  }
  .mobile-middle-header-btn {
    padding: 8px 5px;
  }
  .mobile-header-middle-wrapper .select-language-wrapper {
    height: 35px;
  }
  .mobile-navigation-wrapper {
    position: fixed;
    background: #fff;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 9999;
    overflow-x: auto;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .close-mobile-menu-btn {
    float: right;
    font-size: 22px;
    padding: 5px 10px;
  }
  .mobile-navigation-items {
    clear: both;
    position: relative;
  }
  .mobile-navigation-items ul {
    padding: 0;
    margin: 0;
  }
  .mobile-navigation-items ul li {
    border-bottom: 1px solid #f0f0f0;
    min-height: 50px;
    padding: 15px 0 15px 15px;
    height: auto;
    line-height: normal;
  }
  .mobile-navigation-items ul li {
    border-bottom: 1px solid #f0f0f0;
    min-height: 50px;
    padding: 15px;
    height: auto;
    line-height: normal;
    font-size: 15px;
    font-weight: 500;
    position: relative;
  }
  .mobile-navigation-items ul li a i {
    margin-right: 7px;
  }
  .mobile-navigation-items .has-sub-nav a:after {
    content: "\F105";
    font-family: icons;
    position: absolute;
    right: 15px;
    top: 50%;
    font-weight: 700;
    font-size: 16px;
    -moz-transition: all 0.15s ease-in-out;
    -webkit-transition: all 0.15s ease-in-out;
    -o-transition: all 0.15s ease-in-out;
    -ms-transition: all 0.15s ease-in-out;
    transform: translate(0, -50%);
  }
  .mobile-navigation-items .sub-is-open a:after {
    content: "\F107";
  }
  .mobile-navigation-wrapper.open-mobile-nav {
    margin-left: 0;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
  }
  .mobile-navigation-wrapper {
    margin-left: -100%;
  }

  .dashboard-body {
    padding: 30px 15px;
    word-break: break-word;
    margin-top: 100px;
  }
  .big-title {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: unset;
  }

  /* responsive table */
  .resposive-table-wrapper {
    font-size: 0;
    border-radius: 4px;
    margin-bottom: 10px;
    background: #fff;
    box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .mobile .table-wrapper {
    background: transparent;
    box-shadow: none;
  }
  .responsive-table-row:nth-child(even) {
    background: #f4f5f6;
  }
  .responsive-table-row {
    clear: both;
    display: inline-block;
    width: 100%;
    font-size: 13px;
  }
  .responsive-table-check {
    width: 40%;
    float: left;
    clear: both;
    padding: 10px 5px;
    height: 55px;
    line-height: 35px;
  }
  .responsive-table-action {
    display: inline-block;
    width: 100%;
    clear: both;
    padding: 10px 5px 0;
    border-top: 1px solid #efefef;
  }
  .responsive-table-header-item {
    width: 40%;
    float: left;
    padding: 10px 5px;
    word-break: break-word;
  }
  .responsive-table-data-item {
    width: 60%;
    float: left;
    padding: 10px 5px;
    word-break: break-word;
  }
  .responsive-table-data-item img {
    width: 100px;
    height: 100px;
    -o-object-fit: contain;
    object-fit: contain;
  }
  .responsive-table-action span {
    float: left;
    display: block;
    font-size: 13px;
    margin-right: 10px;
    background: #fff;
    color: #333 !important;
    border-radius: 4px;
    padding: 6px 8px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.2);
    font-weight: 500;
  }
  .resposive-table-all-check {
    padding: 10px 5px;
  }
  .resposive-table-all-check .mat-checkbox-inner-container {
    margin-right: 7px !important;
  }
  .bulk-action-wrapper {
    padding: 5px 15px 5px;
    margin-bottom: 0;
    border-bottom: 1px solid #efefef;
    border-radius: 0;
  }
  .bulk-action-btn {
    margin-top: 5px;
  }
  .bulk-action-btn button {
    margin-bottom: 5px;
  }

  .dashboard-body-top {
    min-height: auto;
    height: auto;
  }
  .advanced-search-wrapper {
    float: none;
    margin-bottom: 15px;
  }
  .advanced-search-input input {
    width: 100%;
    padding: 5px 10px 5px 35px;
    height: 35px;
    line-height: 35px;
  }
  .icon-inside-input i {
    top: 7px;
    font-size: 16px;
  }
  .toggle-search-btn {
    display: block;
    margin: 10px 0;
    float: right;
  }
  .total-items-fiter {
    float: left;
    margin: 10px 0;
  }
  .icon-inside-input {
    width: 100%;
  }
  .advance-search-row {
    display: block;
    margin: 0;
    min-width: 100%;
    width: 100%;
    padding: 10px;
  }
  .select-group-block label {
    margin-bottom: 5px;
  }

  .toggle-advanced-search {
    padding: 0;
  }
  .select-group-block label {
    margin-bottom: 5px;
  }

  .elem-page-wrapper {
    margin-left: 10px;
    width: 145px;
  }
  .clear-btn {
    width: 126px;
  }

  .responsive-table-details {
    float: left;
    width: 60%;
    text-align: right;
    padding: 10px 5px;
  }
  .responsive-table-data-item span {
    padding: 1px 3px;
  }
  .paginator ul.ngx-pagination {
    padding: 0;
  }
  .resposive-table-rating span {
    padding: 0;
  }

  .small-box-header-img {
    padding: 10px 10px 0;
  }
  .small-box-details {
    padding: 15px;
  }
  .small-box-list ul li {
    padding: 15px;
  }

  .medium-title {
    font-size: 16px;
    line-height: 16px;
  }
  .white-box-wrapper {
    padding: 15px;
  }
  .white-box-header {
    padding: 0;
  }
  .white-box-header .medium-title,
  .white-box-header .white-box-header-action {
    margin-bottom: 15px;
  }
  .white-box-body {
    padding: 15px 0;
  }
  .mat-dialog-container .custom-modal-wrapper {
    padding: 0;
    overflow-x: hidden;
  }
  .custom-modal-wrapper .modal-footer-custom {
    float: none;
  }
  .portal-details-wrapper .mat-tab-link {
    margin-left: 10px;
    padding: 0 5px;
  }
  .order-details-box-left {
    float: none;
  }
  .order-details-box-left img {
    float: none;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .order-details-box-top .fl-l,
  .order-details-box-top .fl-r {
    float: none !important;
  }
  .order-details-box-left-header {
    margin-bottom: 10px;
  }
  .order-details-box-right-wrapper {
    float: none;
    /* margin-top: 5px; */
    clear: both;
  }
  .order-details-box-right {
    text-align: left;
  }
  .nsm-dialog.nsm-dialog-open {
    padding: 15px;
  }
  .update-qty {
    float: none;
    display: block;
    clear: both;
    line-height: 13px !important;
    margin: 15px 0;
  }
  .modal-footer {
    float: none;
    display: block;
  }
  .modal-footer button {
    min-width: 130px;
    margin: 0 0 10px 0 !important;
    width: 100%;
    float: none;
  }
  .resposive-user-chat {
    display: block;
  }
  .chat-page-right {
    float: none;
    width: 100%;
    position: relative;
    overflow: auto;
    height: 100%;
    right: 0;
  }
  .msg-input-wrapper {
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    width: 100%;
    box-shadow: 1px 0px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .chat-body {
    height: auto;
  }
  .details-navigation .mat-tab-link {
    margin-left: 10px;
    padding: 5px 0;
  }
  .white-box-footer {
    border-top: 1px dashed #f0f0f0;
    padding-top: 15px;
  }
  .modal-title {
    font-size: 22px;
  }
  .custom-modal-wrapper .modal-footer-custom button {
    min-width: 130px;
    margin: 0 0 10px 0 !important;
    width: 100%;
    float: none;
  }
  .mat-tab-links {
    display: block !important;
    text-align: right;
  }
  /* mat-ink-bar.mat-ink-bar {
  display: none;
} */

  #bulk-action-fixed {
    position: fixed;
    width: 100%;
    top: 100px;
    left: 0;
    z-index: 97;
  }
  /*.cdk-overlay-pane {*/
  /*    position: static !important;*/
  /*    width: 90vw !important;*/
  /*    max-width: 90vw !important;*/
  /*}*/
  /*.cdk-overlay-connected-position-bounding-box {*/
  /*    height: auto !important;*/
  /*    width: auto !important;*/
  /*    top: 50px !important;*/
  /*    left: 50% !important;*/
  /*    transform: translate(-50%, 0);*/
  /*    right: auto !important;*/
  /*}*/
  .custom-mat-menu-wrapper {
    right: 0 !important;
    position: absolute;
  }
  .dashboard-body-top .btn {
    margin-bottom: 10px;
    float: right;
  }
  .dashboard-body-top {
    margin-bottom: 5px;
  }
  .sts-title-m-b {
    margin-bottom: 5px;
  }
  .tab-l-t-r .tab-item {
    margin-right: 5px;
  }
  .dashboard-statistics-box-body {
    padding: 0 15px;
    font-size: 0;
  }
  .dashboard-statistics-box-header {
    position: relative;
  }
  .dashboard-statistics-box,
  .dashboard-statistics-box-body,
  .statistics-table,
  .sts-box-h-50 {
    height: auto;
    overflow: unset;
  }
  .sts-section,
  .sts-content-row-m-b,
  .sts-w-m-m-b {
    margin-bottom: 0;
  }
  .dashboard-statistics-box {
    margin-bottom: 30px !important;
  }
}

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: 9999 !important;
}

.input-box {
  background-color: #ecf2f8;
  border: 1px solid #d0dae4;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
}

.input-title {
  font-weight: 500;
  font-size: 14px;
}
/* end responsive table */
.button-group {
  display: flex;
  gap: 16px;
}
.case-label {
  background: #ecf2f8;
  color: #70777d;
  border: 1px solid #d0dae4;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 10px;
  font-weight: 400 !important;
  width: auto;
}
.visually-hidden {
  display: none;
}
.border-right {
  border-right: 1px solid rgba(112, 119, 125, 0.1);
}

.notification_row {
  border-radius: 4px;

  .notification_icon {
    margin-right: 15px !important;
    width: 22px !important;
  }

  .notification_icon mat-icon {
    overflow: visible !important;
  }

  .notification_text {
    color: #333333;
    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
  }
}

.bg_light_blue {
  background-color: rgba(72, 145, 255, 0.1);
  box-shadow: inset 4px 0 0 0 rgba(72, 145, 255, 0.5);
}

.bg_light_yellow {
  background-color: rgba(250, 176, 17, 0.2);
  box-shadow: inset 4px 0 0 0 rgba(250, 176, 17, 0.5);
}
.bg_light_green {
  background-color: rgba(137, 181, 26, 0.2);
  box-shadow: inset 4px 0 0 0 rgba(137, 181, 26, 0.5);
}
.p-20 {
  padding: 20px !important;
}
.text-right {
  text-align: right !important;
  justify-content: flex-end;

  .mat-sort-header-container,
  .mat-sort-header-content {
    text-align: right;
    justify-content: flex-end;
  }
}
@layer components {
  .status {
    @apply inline-block rounded p-1 font-medium w-full text-center text-white;
  }
}
