.tags {
  .tag {
    &.overdue {
      background: transparent;
      color: #f2545b;
      display: flex;
      align-items: center;
      padding: 0 0 0 10px;

      mat-icon {
        width: 20px;
        height: 20px;
        font-size: 18px;
      }
    }
  }
}
