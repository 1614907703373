@use "@angular/material" as mat;
@use "@angular/material/theming";
@use "./pallets/xpath-accent.scss" as accent;
@use "./pallets/xpath-primary.scss" as primary;

@include mat.typography-hierarchy(mat.define-typography-config());
@include mat.core();

$xpath-primary: mat.define-palette(primary.$md-xpath-primary);
$xpath-accent: mat.define-palette(accent.$md-xpath-accent);

// Create the theme object (a Sass map containing all of the palettes).
$xpath-theme: mat.define-light-theme(
  (
    color: (
      primary: $xpath-primary,
      on-primary: $xpath-primary,
      accent: $xpath-accent,
      tertiary: $xpath-primary,
    ),
    typography: mat.define-typography-config(),
    density: -2,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($xpath-theme);
@include mat.button-theme(
  mat.define-light-theme(
    (
      density: 0,
    )
  )
);

.grey-bg {
  background: mat.get-color-from-palette($xpath-accent, A800) !important;
  color: mat.get-color-from-palette($xpath-accent, 50) !important;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-list-option,
.mat-primary,
.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: #fff;
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}
