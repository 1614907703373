/* For use in src/lib/core/theming/_palette.scss */
$md-xpath-primary: (
  50: #feece6,
  100: #fdd0c2,
  200: #fcb199,
  300: #fb9270,
  400: #fa7a51,
  500: #f96332,
  600: #f85b2d,
  700: #f75126,
  800: #f6471f,
  900: #f53513,
  A100: #fcb199,
  A200: #f96332,
  A400: #f85b2d,
  A700: #f75126,
  contrast: (
    50: #fff,
    100: #fff,
    200: #fff,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #fff,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
